import React, { Component } from "react";
import SingleContent from "components/content/single-content.jsx";
import "./content-modal.css";

class ContentModal extends Component {
  state = {};
  render() {
    return (
      <div className={this.props.isOpen ? "shown" : "hidden"}>
        <div className="overlay"></div>

        <div
          className="content-modal-video-container"
          style={
            this.props.contentUrl.indexOf(".pdf") !== -1 ||
            this.props.contentUrl.indexOf("story") !== -1
              ? { top: "0%" }
              : { top: "25%" }
          }
        >
          <SingleContent link={this.props.contentUrl} />
          <div
            className="content-modal-close-btn"
            onClick={this.props.closeContent}
          >
            &times;
          </div>
        </div>
      </div>
    );
  }
}

export default ContentModal;
