import React, { Component } from "react";
import "./content-page.css";
import Api from "utils/api";

import UserPageTemplate from "components/user-temp/user-pages-template";
import ContentWrap from "components/content/contentWrap";
import nosleep from "../../../../utils/nosleep";

class ContentPage extends Component {
  state = {
    content: {},
  };
  render() {
    return (
      <UserPageTemplate admin noMobile noTop>
        {/* <ContentTop admin /> */}
        <div className="page-title rtl">{this.state.content.Title}</div>
        <ContentWrap
          content={this.state.content}
          admin={this.props.match.params.userId === undefined}
          adminWatchUser={this.props.match.params.userId !== undefined}
        />
      </UserPageTemplate>
    );
  }

  componentDidMount = () => {
    this.getContent();
    nosleep.enable();
    console.log("no sleep enabled");
  };

  getContent = () => {
    let url = "/AdminPanel/GetContent";
    if (this.props.match.params.userId !== undefined) {
      url = "/AdminPanel/GetUserContent";
    }
    Api.post(url, {
      ContentId: this.props.match.params.id,
      UserId: this.props.match.params.userId,
    })
      .then((response) => {
        const {
          data: { Payload, Success /*,  Message, ErrorCode*/ },
        } = response;
        if (Success) this.setState({ content: Payload });
        else this.setState({ content: { Title: "תוכן לא קיים או לא פעיל" } });
      })
      .catch(() => {});
  };
  componentWillUnmount = () => {
    nosleep.disable();
    console.log("no sleep disabled");
  };
}
export default ContentPage;
