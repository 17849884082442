import React, { Component } from "react";
import "./navbar.css";
import { NavLink } from "react-router-dom";
import Api from "utils/api";

import history from "utils/history";
import { withRouter } from "react-router-dom";

class Navbar extends Component {
  state = {
    mobileMenuOpen: false,
    notificationMenuOpen: false,
    logOutModal: false,
    messages: [],
  };

  toggleMobileMenu = () => {
    // console.log(this.state);
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
      notificationMenuOpen: false,
    });
  };

  toggleNotificationMenu = () => {
    //if (this.state.messages.length > 0) {
    // console.log(this.state);
    this.setState({
      mobileMenuOpen: false,
      notificationMenuOpen: !this.state.notificationMenuOpen,
    });
    // }
  };

  componentWillMount() {
    this.getMessages();
  }
  getMessages = () => {
    Api.post("/OnBoard/GetPersonalMessages")
      .then((response) => {
        const {
          data: { Payload /*, Success,Message, ErrorCode*/ },
        } = response;
        this.setState({
          messages: Payload,
        });
      })
      .catch(() => {});
  };
  closeLogout = () => {
    this.setState({ logOutModal: false });
  };
  openLogout = () => {
    this.setState({ logOutModal: true });
  };
  goLogout = () => {
    history.push("/logout");
  };
  render() {
    //const startUrl = this.props.admin ? "/rakazut" : "/user/feed";
    const indexUrl = this.props.admin ? "/rakazut" : "/user/feed";
    const newMessagesLength = this.state.messages.filter(
      (ms) => ms.New === true
    ).length;
    return (
      <React.Fragment>
        {this.state.logOutModal ? (
          <React.Fragment>
            <div className="screen-overlay" onClick={this.closeLogout} />
            <div className="logout-dialog text-blue font-20px font-weight-bold">
              יציאה
              <br />
              <button onClick={this.goLogout}>רוצה לצאת</button>
              <button onClick={this.closeLogout}>רוצה להישאר</button>
            </div>
          </React.Fragment>
        ) : null}
        <div className="container-fluid top-navbar hidden-mobile">
          <div className="row w-100">
            <div className="col-md-3 text-center">
              <li
                className="w-25"
                onClick={this.openLogout}
                style={{ cursor: "pointer" }}
              >
                סיימתי
              </li>
              <li className="w-25">
                <img
                  onClick={this.toggleNotificationMenu}
                  className="nav-notification"
                  src={require("../../images/envelope.png")}
                  alt=""
                />
                <span
                  className={
                    newMessagesLength > 0
                      ? "notification-count-circle"
                      : "hidden"
                  }
                >
                  <span className="notification-count">
                    {newMessagesLength}
                  </span>
                </span>
              </li>
            </div>
            <div className="col-md-9 text-center">
              {this.props.location.pathname.indexOf("/rakazut") !== -1 ? (
                ""
              ) : (
                <NavLink to={"/user/help"} activeClassName="active">
                  {/* <NavLink to={`/${startUrl}/help`} activeClassName="active"> */}
                  <li style={{ width: "20%" }}>עזרה</li>
                </NavLink>
              )}
              {!this.props.admin ? (
                <React.Fragment>
                  <NavLink to="/user/contents" activeClassName="active">
                    <li style={{ width: "20%" }}>התכנים שלי</li>
                  </NavLink>
                  <NavLink to="/user/progress" activeClassName="active">
                    <li style={{ width: "20%" }}>ההתקדמות שלי</li>
                  </NavLink>
                </React.Fragment>
              ) : (
                <NavLink
                  exact
                  to="/rakazut/manage-contents"
                  activeClassName="active"
                >
                  <li style={{ width: "20%" }}>ניהול תכנים</li>
                </NavLink>
              )}

              <NavLink exact to={indexUrl} activeClassName="active">
                <li style={{ width: "20%" }}>MAX ראשי</li>
              </NavLink>
            </div>
          </div>
        </div>
        {!this.props.noMobile && (
          <div className="show-mobile mobile-nav">
            {/* <img
              onClick={this.toggleMobileMenu}
              className="mobile-nav-hamburger"
              src={require("../../images/hamburger.png")}
              alt=""
            /> */}
            {/* Nav Menu Mobile */}
            <div className={this.state.mobileMenuOpen ? "shown" : "hidden"}>
              <div className="mobile-nav-container">
                <ul className="mobile-nav">
                  <span
                    onClick={this.toggleMobileMenu}
                    className="close-menu-btn"
                  >
                    &times;
                  </span>
                  <NavLink to={indexUrl} exact activeClassName="active">
                    <li>ראשי MAX</li>
                  </NavLink>
                  {!this.props.admin ? (
                    <React.Fragment>
                      <NavLink to="/user/progress" activeClassName="active">
                        <li>ההתקדמות שלי</li>
                      </NavLink>
                      <NavLink to="/user/contents" activeClassName="active">
                        <li>התכנים שלי</li>
                      </NavLink>
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.props.location.pathname.indexOf("/rakazut") !== -1 ? (
                    ""
                  ) : (
                    // <NavLink to={`/${startUrl}/help`} activeClassName="active">
                    <NavLink to={`/user/help`} activeClassName="active">
                      <li>עזרה</li>
                    </NavLink>
                  )}

                  <div
                    className="log-out-mobile-btn"
                    onClick={this.openLogout}
                    style={{ cursor: "pointer" }}
                  >
                    סיימתי
                  </div>
                </ul>
              </div>
            </div>

            {/* <img
              className="mobile-nav-logo"
              src={require("../../images/max_logo_blue.svg")}
              alt=""
            />
            <img
              onClick={this.toggleNotificationMenu}
              className="mobile-nav-notification"
              src={require("../../images/envelope.png")}
              alt=""
            />
            <div className={newMessagesLength > 0 ? "shown" : "hidden"}>
              <span className="notification-count-mobile">
                {newMessagesLength}
              </span>
            </div> */}

            {/* New nav header */}
            <div>
              <div
                className={
                  this.props.location.pathname === "/user/contents" ||
                  this.props.location.pathname === "/user/progress" ||
                  this.props.location.pathname.indexOf("/user/contents/") !==
                    -1 ||
                  this.props.location.pathname.indexOf("/rakazut/users") !== -1
                    ? "new-nav-fixed d-md-none"
                    : "new-nav d-md-none"
                }
              >
                {this.props.location.pathname === "/user/feed" ||
                this.props.location.pathname === "/rakazut" ? (
                  <div>
                    <div className={newMessagesLength > 0 ? "shown" : "hidden"}>
                      <span className="notification-count-mobile">
                        {newMessagesLength}
                      </span>
                    </div>
                    <img
                      onClick={this.toggleNotificationMenu}
                      className="new-max-envelope"
                      src={require("../../images/envelope.png")}
                      alt=""
                    />
                  </div>
                ) : (
                  <img
                    onClick={this.goBack}
                    className="new-back"
                    src={require("../../images/back-arrow.png")}
                    alt=""
                  />
                )}
                <img
                  className="new-max-logo"
                  src={require("../../images/max_logo_blue.svg")}
                  alt=""
                />
                <img
                  onClick={this.toggleMobileMenu}
                  className="new-max-hamburger"
                  src={require("../../images/hamburger.png")}
                  alt=""
                />
                <div
                  className={this.props.messageLength > 0 ? "shown" : "hidden"}
                >
                  <span className="new-notification-count">
                    {this.props.messageLength}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* notification menu */}

        <div className={this.state.notificationMenuOpen ? "shown" : "hidden"}>
          <div className="notification-menu no-padding no-margin rtl">
            <h4 className="font-weight-bold my-3 pt-5 px-4 text-center">
              {this.state.messages.length === 0
                ? "פה אמורות להיות ההודעות האישיות שלך. זה לא שהתעצלנו לכתוב, פשוט כרגע אין לנו מה לעדכן"
                : "מזכירים לך"}
            </h4>
            <span
              onClick={this.toggleNotificationMenu}
              className="close-menu-btn"
            >
              &times;
            </span>
            <ul className="notification-container no-padding font-20px">
              {this.state.messages.map((item, index) => {
                return (
                  <li
                    onClick={() =>
                      this.markPersonalMessage(
                        item.MessageId,
                        item.Link ? item.Link : "#"
                      )
                    }
                    key={index}
                    className={
                      "notification d-block text-right m-2 p-3" +
                      (index + 1 !== this.state.messages.length
                        ? " notification-border-bottom"
                        : "") +
                      (item.New ? " font-weight-bold" : "")
                    }
                  >
                    {item.Type === 1 ? (
                      <img
                        className="notification-type-img ml-3"
                        src={require("../../images/Clock2.png")}
                        alt=""
                      />
                    ) : item.Type === 2 ? (
                      <img
                        className="notification-type-img ml-3"
                        src={require("../../images/Megaphone.png")}
                        alt=""
                      />
                    ) : item.Type === 3 ? (
                      <img
                        className="notification-type-img ml-3"
                        src={require("../../images/Trophy.png")}
                        alt=""
                      />
                    ) : item.Type === 4 ? (
                      <img
                        className="notification-type-img ml-3"
                        src={require("../../images/Hill.png")}
                        alt=""
                      />
                    ) : null}
                    <div
                      style={{ cursor: "pointer" }}
                      dangerouslySetInnerHTML={{ __html: item.Message }}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {/* end notification menu 
                      <li className="notification font-weight-bold d-block text-right m-2 p-3 notification-border-bottom">
                יש לבצע את המשימות של השבוע השלישי
              </li>
              <li className="notification d-block text-right m-2 p-3 notification-border-bottom">
                סיימת את המשימות של השבוע הראשון!
              </li>
              <li className="notification d-block text-right m-2 p-3">
                ברוכים הבאים לאפליקציה! התחל את המשימות של השבוע הראשון
              </li>
        
        */}
      </React.Fragment>
    );
  }

  markPersonalMessage = (id, to) => {
    Api.post("/OnBoard/MarkPersonalMessage", { MessageId: id })
      .then((response) => {
        /*
        const {
          data: { Success, Payload, Message, ErrorCode }
        } = response;
        */
        // console.log(Payload);
        this.getMessages();
        if (to !== "#") {
          history.push(to);
          return;
        }
      })
      .catch(() => {});
  };

  goBack = () => {
    history.goBack();
  };
}

export default withRouter(Navbar);
