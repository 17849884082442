import React, { Component } from "react";
import "./welcome.css";

class Welcome extends Component {
  state = {};
  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row my-vh100">
            <div className="col-md-4 welcome-img d-flex align-items-center justify-content-center">
              <img
                className="max-logo screen-centered show-mobile"
                src={require("../../images/max_logo_white.svg")}
                alt=""
              />
            </div>
            <div className="col-md-8 d-flex align-items-center justify-content-center">
              <div className="screen-centered">
                <img
                  className="max-logo hidden-mobile"
                  src={require("../../images/max_logo_blue.svg")}
                  alt=""
                />
                <div className="welcome-text rtl text-center mt-4 text-blue">
                  איזה כיף שהצטרפת!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  componentDidMount() {
    setTimeout(() => {
      this.props.history.push("/");
    }, 5000);
  }
}

export default Welcome;
