import React, { useState } from "react";
import Input from "./input";

export default function AddContent({ addContent, catId, displayOrder, modal }) {
  const [info, setInfo] = useState({
    Name: "",
    CategoryId: catId,
    Description: "",
    StartDays: "",
    EndDays: "",
    MinutesRequired: "",
    DisplayOrder: displayOrder,
    FeedDisplayOrder: displayOrder,
    Refresh: 0,
    Active: true,
  });

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "2em",
        display: "flex",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setIsUploading(true);
          addContent(info, file, image, modal).then(() =>
            setIsUploading(false)
          );
        }}
      >
        <Input
          value={info.Name}
          func={setInfo}
          type={"text"}
          name={"Name"}
          label={"שם התוכן"}
          data={info}
        />
        <Input
          value={info.Description}
          func={setInfo}
          type={"text"}
          name={"Description"}
          label={"תיאור התוכן"}
          data={info}
          textarea={true}
        />

        <Input
          value={info.StartDays}
          func={setInfo}
          type={"number"}
          name={"StartDays"}
          label={"יום התחלה"}
          data={info}
        />
        <Input
          value={info.EndDays}
          func={setInfo}
          type={"number"}
          name={"EndDays"}
          label={"יום סיום"}
          data={info}
        />
        <Input
          value={info.MinutesRequired}
          func={setInfo}
          type={"number"}
          name={"MinutesRequired"}
          label={"זמן קריאה"}
          data={info}
        />
        {info.Refresh !== -1 ? (
          <Input label={"קובץ"} func={setFile} file={true} />
        ) : (
          <div></div>
        )}
        <Input label={"תמונה"} func={setImage} file={true} />
        <Input
          label={"דף"}
          func={setInfo}
          value={info.Refresh}
          type="checkbox"
          name={"Refresh"}
          data={info}
          refresh={true}
        />

        <button
          type="submit"
          className="max-form-btn max-login-btn mt-5"
          disabled={isUploading}
        >
          {isUploading ? "טוען..." : "הוסף"}
        </button>
      </form>
    </div>
  );
}
