import React, { Component } from "react";
import LoginTemplate from "components/login-temp/login-temp.jsx";
import Api from "utils/api";
import { EmailRegEx, Errors } from "utils/const";
class ForgotPassword extends Component {
  state = {
    email: "",
    sent: false,
    message: ""
  };
  render() {
    return (
      <LoginTemplate>
        <img
          className="max-logo hidden-mobile mb-5"
          src={require("../../images/max_logo_blue.svg")}
          alt=""
        />

        {this.state.sent ? (
          <div className="login-text rtl text-center text-blue">
            נשלחו הוראות איפוס סיסמה לדואר אלקטרוני
          </div>
        ) : (
          <div>
            <div className="login-text rtl text-center text-blue">
              לא נורא, קורה לכולם
            </div>
            <div className="rtl text-error text-center error-line">
              {this.state.message}
            </div>
            <form className="input-field-container" onSubmit={this.submitLogin}>
              <input
                type="text"
                name="email"
                value={this.state.email}
                onChange={this.change}
                className="input-field mb-1"
                placeholder="דואר אלקטרוני"
              />
              <input
                type="submit"
                className="max-btn max-login-btn mt-5"
                value="שלח"
              />
            </form>
          </div>
        )}
      </LoginTemplate>
    );
  }
  change = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  submitLogin = e => {
    e.preventDefault();
    if (this.state.email.trim() === "") {
      this.setState({
        message: Errors.e50
      });
      return;
    } else if (!EmailRegEx.test(this.state.email)) {
      this.setState({
        message: Errors.e53
      });
      return;
    }
    let send = {
      Email: this.state.email
    };
    Api.post("OnBoard/ChangePasswordReq", send)
      .then(response => {
        const {
          data: { Success /*, Payload*/, Message, ErrorCode }
        } = response;
        if (Success) {
          this.setState({
            sent: true
          });

          setTimeout(() => {
            this.props.history.push("/");
          }, 5000);
        } else {
          this.setState({
            message: Errors["e" + ErrorCode] ? Errors["e" + ErrorCode] : Message
          });
        }
      })
      .catch(() => {});
  };
}

export default ForgotPassword;
