import React, { Component } from "react";
import "./admin-panel.css";
import NavBar from "components/navbar/navbar.jsx";
import history from "utils/history.js";
import { NavLink } from "react-router-dom";
import { format } from "date-fns";
import Auth from "utils/auth";
class AdminPanel extends Component {
  state = {};
  render() {
    const date = format(new Date(Auth.lastSeen()), "dd/MM/yyyy HH:mm");
    return (
      <div>
        <NavBar admin mobile />
        <div className="container-fluid">
          <div className="row my-vh100">
            <div
              className={
                "col-md-3 hidden-mobile-d-flex bg-teal align-items-center justify-content-center " +
                this.props.imgClass
              }
            >
              <div className="ie-fix" />
              <img
                className="temp1-max-logo hidden-mobile"
                src={require("../../images/max_logo_white.svg")}
                alt=""
              />
            </div>
            <div className="col-md-9 d-flex align-items-center justify-content-center">
              <div className="screen-centered rtl">
                {/* <div className="pt-5" /> */}
                <div className="pt-5 hidden-mobile" />
                {/* <div className="pt-4 show-mobile" /> */}
                <div className="hidden-mobile">
                  <div
                    className="text-center"
                    style={{ margin: "0px 0px 20px 0px" }}
                  >
                    התחברת לאחרונה {date}
                  </div>
                  <div className="in-admin-menu-web">
                    <NavLink to="/rakazut/users" className="box l1">
                      כל המשתמשים
                    </NavLink>
                    <NavLink to="/rakazut/users/done" className="box l2">
                      משתמשים שסיימו
                    </NavLink>
                    <NavLink to="/rakazut/users/low" className="box l3 ">
                      משתמשים עם אחוז ביצוע נמוך
                    </NavLink>
                    <NavLink to="/rakazut/users/late" className="box l4">
                      משתמשים שלא סיימו בזמן
                    </NavLink>
                    <NavLink to="/rakazut/create-user" className="box l5">
                      חדש
                    </NavLink>
                    <NavLink to="/rakazut/users/contents" className="box l6">
                      כל התכנים
                    </NavLink>

                    <div className="box-rowpan" />
                  </div>
                </div>
                <div className="show-mobile">
                  <div
                    className="text-center"
                    style={{ margin: "0px 0px 20px 0px" }}
                  >
                    התחברת לאחרונה {date}
                  </div>
                  <div className="in-admin-menu px-1">
                    <div className="mb-4 d-flex">
                      <NavLink
                        to="/rakazut/create-user"
                        className="max-form-btn w-50"
                      >
                        חדש
                      </NavLink>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <NavLink
                        to="/rakazut/users/contents"
                        className="max-form-btn w-50"
                      >
                        כל התכנים
                      </NavLink>
                    </div>
                    <NavLink to="/rakazut/users">
                      <div className="image-menu all-users">
                        <div className="image-menu-img" />
                        <div className="image-menu-text">כל המשתמשים</div>
                      </div>
                    </NavLink>
                    <NavLink to="/rakazut/users/done">
                      <div className="image-menu finished-users">
                        <div className="image-menu-img" />
                        <div className="image-menu-text">משתמשים שסיימו</div>
                      </div>
                    </NavLink>
                    <NavLink to="/rakazut/users/low">
                      <div className="image-menu low-users">
                        <div className="image-menu-img" />
                        <div className="image-menu-text">
                          משתמשים עם אחוז ביצוע נמוך
                        </div>
                      </div>
                    </NavLink>
                    <NavLink to="/rakazut/users/late">
                      <div className="image-menu late-users">
                        <div className="image-menu-img" />
                        <div className="image-menu-text">
                          משתמשים שלא סיימו בזמן
                        </div>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  goHome = () => {
    history.push("/rakazut");
  };
}

export default AdminPanel;
