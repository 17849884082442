import React, { useState, useEffect, useRef } from "react";
import Catagory from "./catagory";
import AddCatagory from "./add-catagory";
import Api from "utils/api";
import Modal from "./modal";
import AdminList from "components/admin-temp/admin-list-template.jsx";
import ToggleSwitch from "./toggle-switch/toggle-switch";
import "../admin/users/users/users.css";

import placeholder from "../../images/download.jpeg";

export default function ManageContents() {
  const [catagories, setCatagories] = useState([]);

  const [filterActive, setFilterActive] = useState(false);

  const [search, setSearch] = useState("");

  const modal = useRef(null);
  const errorModal = useRef(null);
  const successModal = useRef(null);

  const getCategories = () => {
    Api.get("/AdminPanel/GetCategoreisContentList").then((response) => {
      if (response.data.Success) setCatagories(response.data.Payload);
      else console.log("error");
    });
  };

  const doSearch = (e) => {
    setSearch(e.target.value);
  };
  const activeOnly = (category) => {
    if (filterActive) return category.Active;
    return true;
  };

  const searchIt = (category) => {
    if (search.trim() === "") return true;
    if (category.Name.includes(search)) return true;
  };

  const _categories = catagories.filter(activeOnly).filter(searchIt);
  const categories = _categories;

  useEffect(() => {
    getCategories();
  }, []);

  const addCatagory = (name, DisplayOrder, modal) => {
    Api.post("AdminPanel/CreateCategory", { Name: name, DisplayOrder }).then(
      (response) => {
        if (response.data.Success) {
          getCategories();
          modal.current.close();
          successModal.current.open();
        } else errorModal.current.open();
      }
    );
  };

  const changeCatagoryOrder = (catId, name, displayOrder, active) => {
    Api.post("AdminPanel/UpdateCategory", {
      Code: catId,
      Name: name,
      DisplayOrder: displayOrder,
      Active: active,
    }).then((response) => {
      if (response.data.Success) getCategories();
      else alert("שגיאה");
    });
  };

  const addContent = (info, ContentFile, ImageFile, modal) => {
    // if (ContentFile === null) {
    //   if (info.Refresh === 0) {
    //     return alert("Please add a file");
    //   }
    // }

    if (ImageFile === null) {
      ImageFile = placeholder;
    }

    // if (ContentFile !== null && info.Refresh !== 0) {
    //   ContentFile = null;
    // }
    info = { ...info, StartDays: (info.StartDays -= 1) };
    let form = new FormData();

    form.append("data", JSON.stringify(info));
    form.append("ContentFile", ContentFile);
    form.append("ImageFile", ImageFile);

    return Api.post("AdminPanel/CreateContent", form).then((response) => {
      if (response.data.Success) {
        getCategories();
        modal.current.close();
        successModal.current.open();
      } else errorModal.current.open();
    });
  };

  const setActiveContent = (id, active) => {
    Api.post("AdminPanel/SetActiveContent", { Id: id, Active: !active }).then(
      (response) => {
        if (response.data.Success) getCategories();
        else alert("error");
      }
    );
  };

  const setContent = (items) => {
    Api.post("AdminPanel/OrderContents", {
      Obj: items.map((item, index) => {
        return {
          Id: item.Id,
          DisplayOrder: index,
        };
      }),
    }).then((response) => {
      if (response.data.Success) {
        getCategories();
        successModal.current.open();
      } else errorModal.current.open();
    });
  };

  const setRefresherContent = (items) => {
    Api.post("AdminPanel/OrderContents", {
      Obj: items.map((item, index) => {
        return {
          Id: item.Id,
          DisplayOrder: index,
        };
      }),
    }).then((response) => {
      if (response.data.Success) {
        getCategories();
        successModal.current.open();
      } else errorModal.current.open();
    });
  };

  const setActiveCategory = (id, active) => {
    Api.post("AdminPanel/SetActiveCategory", {
      Code: id,
      Active: !active,
    }).then((response) => {
      if (response.data.Success) getCategories();
      else alert("error");
    });
  };

  const edit = (info, file, image, modal, active) => {
    console.log(active, info.Active, info.active);

    info = { ...info, StartDays: (info.StartDays -= 1), Active: info.Active };
    let form = new FormData();

    form.append("data", JSON.stringify(info));
    form.append("ContentFile", file);
    form.append("ImageFile", image);

    return Api.post("AdminPanel/UpdateContent", form).then((response) => {
      if (response.data.Success) {
        getCategories();
        modal.current.close();
        successModal.current.open();
      } else alert("error");
    });
  };

  const editCategory = (catId, name, displayOrder, active, modal) => {
    Api.post("AdminPanel/UpdateCategory", {
      Code: catId,
      Name: name,
      DisplayOrder: displayOrder,
      Active: active,
    }).then((response) => {
      if (response.data.Success) {
        getCategories();
        if (modal) modal.current.close();
        successModal.current.open();
      } else errorModal.current.open();
    });
  };

  return (
    <AdminList dontShowMax={true}>
      <div
        style={{
          maxWidth: "2000px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="page-title rtl mb-3 font-weight-bold">כל התכנים</div>
        <div>
          <input
            style={{ borderRadius: "20px", padding: "5px 0px" }}
            type="text"
            className="searchInput text-center"
            placeholder="מחפשים קטגוריה ספציפית? רשמו את השם פה"
            value={search}
            onChange={doSearch}
          />
        </div>
        <div className="activeSec my-4">
          <span className="activeSec-text"> הראה רק קטגוריות פעילות</span>
          <ToggleSwitch
            onPress={() => {
              setFilterActive(!filterActive);
            }}
            isChecked={filterActive}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "1em",
          }}
        >
          <button
            className="max-form-btn max-login-btn mt-3"
            style={{ marginBottom: "0.5em" }}
            onClick={() => modal.current.open()}
          >
            הוספת קטגוריה
          </button>
          <Modal ref={modal}>
            <AddCatagory
              modal={modal}
              displayOrder={catagories.length}
              addCatagory={addCatagory}
            />
          </Modal>
        </div>
        {categories.map((catagory) => {
          return (
            <Catagory
              setRefresherContent={setRefresherContent}
              setContent={setContent}
              editCategory={editCategory}
              setActiveCategory={setActiveCategory}
              changeCatagoryOrder={changeCatagoryOrder}
              getCategories={getCategories}
              content={catagory.GetContentList}
              addContent={addContent}
              name={catagory.Name}
              key={catagory.Code}
              id={catagory.Code}
              displayOrder={catagory.DisplayOrder}
              setActiveContent={setActiveContent}
              edit={edit}
              active={catagory.Active}
            />
          );
        })}
        <Modal error={true} ref={errorModal}>
          <div
            style={{
              backgroundColor: "white",
              fontSize: "2em",
              padding: "1em",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p>שגיאה בהוספת תוכן</p>
            <button onClick={() => errorModal.current.close()}>המשך</button>
          </div>
        </Modal>
        <Modal ref={successModal}>
          <div
            style={{
              backgroundColor: "white",
              fontSize: "2em",
              padding: "1em",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p>הפעולה התבצעה בהצלחה</p>
          </div>
        </Modal>
      </div>
    </AdminList>
  );
}
