import React, { Component } from "react";
import "./story-carousel.css";

class StoryModal extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className={this.props.isOpen ? "shown" : "hidden"}>
          <div className="overlay"></div>

          <div className="story-video-container">
            <video
              key={this.props.videoUrl}
              className="story-video"
              controls
              autoPlay
            >
              <source src={this.props.videoUrl} />
            </video>
            <div className="story-close-btn" onClick={this.props.closeStory}>
              &times;
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StoryModal;
