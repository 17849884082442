import React, { Component } from "react";
import "./login-temp.css";
class LoginTemplate extends Component {
  state = {};
  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row my-vh100">
            <div className="col-md-4 login-img d-flex align-items-center justify-content-center">
              <div className="ie-fix" />
              <img
                className="max-logo screen-centered show-mobile"
                src={require("../../images/max_logo_white.svg")}
                alt=""
              />
            </div>
            <div className="col-md-8 d-flex align-items-center justify-content-center">
              <div className="screen-centered">{this.props.children}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginTemplate;
