import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "utils/history";
import ChangePassword from "./change-password";
import ForgotPassword from "./forgot-password";
class PasswordPages extends Component {
  render() {
    /*         <Navbar /> */
    return (
      <div>
        <Router history={history}>
          <Switch>
            <Route
              path="/password/set/:UserId/:Token"
              exact
              render={props => <ChangePassword newuser {...props} />}
            />
            <Route
              path="/password/change/:UserId/:Token"
              exact
              render={props => <ChangePassword {...props} />}
            />
            <Route
              path="/password/forgot"
              exact
              render={props => <ForgotPassword {...props} />}
            />
            <Route
              render={props => (
                <Redirect
                  to={{
                    pathname: "/"
                  }}
                />
              )}
            />{" "}
          </Switch>
        </Router>
      </div>
    );
  }
}

export default PasswordPages;
