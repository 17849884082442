import React, { Component } from "react";

import Api from "utils/api.js";

class Content extends Component {
  state = {
    content: {},
  };
  componentDidMount() {
    if (this.video !== undefined) {
      this.video.onplay = () => {
        this.watched_refresh(this.props.content.ContentId);
      };
    }
  }
  watched_refresh = (id) => {
    Api.post("/OnBoard/MarkRefreshWatched", {
      ContentId: id,
    })
      .then((response) => {})
      .catch(() => {});
  };
  render() {
    const add = process.env.REACT_APP_CONTENT_BASE_URL;
    const type_help = this.props.content.Link
      ? this.props.content.Link.split(".")
      : [""];
    let type = "iframe";
    switch (type_help[type_help.length - 1].toLowerCase()) {
      case "mp4":
      case "mov":
        type = "video";
        break;
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
        type = "image";
        break;
      case "pdf":
        type = "PDF";
        break;
      default:
        type = "iframe";
    }
    if (this.props.content.Link === undefined) {
      return <div />;
    }
    return (
      <div>
        <div
          className="rtl text-right content-description text-dark no-padding mb-4"
          dangerouslySetInnerHTML={{ __html: this.props.content.Description }}
        ></div>
        <div className="content-box">
          {type === "video" && (
            <video
              className="in-content-video"
              controls
              ref={(video) => (this.video = video)}
            >
              <source src={add + this.props.content.Link} />
            </video>
          )}
          {type === "image" && (
            <img
              src={add + this.props.content.Link}
              className="in-content-image"
              alt=""
            />
          )}
          {type === "PDF" && (
            <iframe
              title="contentFrame"
              src={
                add + "/PDFJS/web/viewer.html?file=" + this.props.content.Link
              }
              frameBorder="0"
              width="100%"
              className="in-content-iframe"
            />
          )}{" "}
          {type === "iframe" && (
            <iframe
              title="contentFrame"
              src={add + this.props.content.Link}
              frameBorder="0"
              width="100%"
              className="in-content-iframe"
            />
          )}
          {/*/*
                    <iframe
            title="contentFrame"
            src={this.props.content.Link}
            frameBorder="0"
            width="100%"
            assName="content-iframe"
          />

           */}
          <div className="content-ruller">
            <React.Fragment>
              <span className="float-right">
                {this.props.admin && (
                  <React.Fragment>
                    &nbsp;
                    {`${this.props.content.Watches}/${this.props.content.Total}`}
                    &nbsp;
                  </React.Fragment>
                )}
                <img
                  src={require("../../images/eye.png")}
                  alt=""
                  className={
                    this.props.admin || this.props.content.Watched
                      ? "content-eye-img"
                      : "hidden"
                  }
                />
              </span>

              <span className="float-left">
                {this.props.admin || this.props.content.Like ? (
                  <img
                    onClick={() =>
                      !this.props.admin &&
                      !this.props.adminWatchUser &&
                      this.props.toggleLiked(this.props.content.ContentId)
                    }
                    src={require("../../images/full-heart.png")}
                    alt=""
                    className="content-heart-img content-heart-img-zoom"
                  />
                ) : (
                  <img
                    onClick={() =>
                      !this.props.adminWatchUser &&
                      this.props.toggleLiked(this.props.content.ContentId)
                    }
                    src={require("../../images/heart.png")}
                    alt=""
                    className="content-heart-img content-heart-img-zoom"
                  />
                )}
                {this.props.admin && (
                  <React.Fragment>
                    &nbsp;
                    {`${this.props.content.Likes}/${this.props.content.Total}`}
                    &nbsp;
                  </React.Fragment>
                )}
              </span>
            </React.Fragment>
          </div>
        </div>
      </div>
    );
  }
}
export default Content;
