import React, { Component } from "react";
import "./progress.css";
import Api from "utils/api";
import Slider from "rc-slider";
import { Link } from "react-router-dom";
import UserPageTemplate from "components/user-temp/user-pages-template";
const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  //console.log(restProps);
  return <Slider.Handle {...restProps} className="handle" />; //<Slider.Handle value={value} {...restProps} />;
};
class Progress extends Component {
  state = {
    progress: []
  };
  render() {
    const trackTrackStyle = { backgroundColor: "#231162" };
    const railTrackStyle = { backgroundColor: "#231162" };
    /*
    const handleStyle = {
      
      
      backgroundColor: "#8cead5",
      borderColor: "#8cead5",
      width: "30px",
      height: "30px",
      marginTop: "-12px"
    };
    */
    /*
      const handleStyle = {
      width: 0,
      height: 0,
      border: 0,
      backgroundColor: "transparent",
      margin: "-13px -15px",
      borderTop: "15px solid transparent",
      borderBottom: "15px solid transparent",
      borderRadius: 0,
      borderRight: "15px solid #84d8c6"
      };
      */

    return (
      <React.Fragment>
        <div className="mobile-vh10" />
        <UserPageTemplate noMobile imgClass="user-progress-img">
          <div
            className="white-bg-mobile"
            style={{
              maxWidth: "800px",
              margin: "0px auto",
              padding: "20px"
            }}
          >
            <div className="page-title mb-4 rtl font-weight-bold">
              ההתקדמות שלי
            </div>

            <div className="row vh100">
              <div className="col-md-6 offset-md-3 mt-md-5 text-right rtl mobile-order-1">
                <div className="mb-2 stat">
                  <span className="img-wrap">
                    <img
                      className="ml-4"
                      src={require("../../../images/calendar.png")}
                      width="40"
                      alt=""
                    />
                  </span>
                  {parseInt(this.state.progress.DaysHere, 10) +
                    1 +
                    " ימים אני פה"}
                </div>
                <div className="stat">
                  <div>
                    <span className="img-wrap">
                      <img
                        style={{ marginBottom: "-28px" }}
                        className="float-right"
                        src={require("../../../images/flag.png")}
                        width="40"
                        alt=""
                      />
                    </span>
                    <Link to="/user/contents">
                      {this.state.progress.CompletedPer + "% מהתכנים כבר למדתי"}
                    </Link>

                    <img
                      className="mr-4 crown float-left"
                      style={{ paddingTop: "10px" }}
                      src={require("../../../images/crown.png")}
                      width="40"
                      alt=""
                    />
                    <div
                      style={{
                        width: "76%",
                        pointerEvents: "none",
                        marginRight: "25px"
                      }}
                    />
                    {/**/}
                    <Slider
                      style={{ width: "68%", margin: "0 auto" }}
                      className="mt-2"
                      value={100 - this.state.progress.CompletedPer}
                      trackStyle={trackTrackStyle}
                      railStyle={railTrackStyle}
                      handle={handle}
                      min={0}
                      max={100}
                    />
                  </div>
                </div>
                <div className="mb-2 stat">
                  <span className="img-wrap">
                    <img
                      className="ml-4"
                      src={require("../../../images/sandclock.png")}
                      width="40"
                      alt=""
                    />
                  </span>
                  <Link to="/user/feed">
                    {"יש " +
                      this.state.progress.PendingContents +
                      " תכנים שמחכים לי"}
                    <img
                      className="ml-4 mr-2 mt-1"
                      style={{ zoom: "1.5" }}
                      src={require("../../../images/left-arrow.png")}
                      width="40"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
              {/* <div className="col-md-4 text-center text-md-right text-blue font-weight-bold rtl">
              <span className="font-1dot2em d-md-block mx-2 mb-md-4">
                {this.state.progress.Fullname}
              </span>
              <span className="font-1dot2em d-md-block mx-2 mb-md-4">
                {this.state.progress.Role}
              </span>
              <span className="font-1dot2em d-md-block mx-2 mb-md-4">
                {this.state.progress.Location}
              </span>
            </div> */}
            </div>
          </div>
        </UserPageTemplate>
      </React.Fragment>
    );
  }

  componentDidMount = () => {
    Api.post("/OnBoard/GetMyAdvance")
      .then(response => {
        const {
          data: { Payload /*,Success, Message, ErrorCode*/ }
        } = response;
        // console.log(Payload);
        this.setState({
          progress: Payload
        });
      })
      .catch(() => {});
  };
}

export default Progress;
