import React from "react";

export default function ContentHeaders() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1em",
        fontWeight:"bold",
        borderBottom:"1px solid #e6e0e0",
        margin:"0em 0.5em 0.5em 0.5em"
      }}
    >
      <div
        style={{ maxWidth: "30px", minWidth: "30px", marginLeft: "1.5em" }}
      ></div>
      <div
        style={{ maxWidth: "100px", minWidth: "100px", marginLeft: "2.5em" }}
      >
        {" "}
        שם התוכן{" "}
      </div>
      <div style={{}}>תיאור</div>
      <div style={{}}>יום התחלה</div>
      <div style={{}}>יום סיום</div>
      <div style={{}}>זמן קריאה</div>
      <div style={{}}>קובץ/דף</div>
      <div style={{}}>פעיל/לא פעיל</div>
    </div>
  );
}
