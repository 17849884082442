import React, { Component } from "react";
import "./admin-pages-template.css";
import NavBar from "components/navbar/navbar.jsx";
import history from "utils/history.js";
class AdminPages extends Component {
  state = {};
  render() {
    return (
      <div>
        <NavBar admin />
        <div className="container-fluid">
          <div className="row my-vh100">
            <div
              className={
                "col-md-3 bg-teal back-image d-flex align-items-center justify-content-center " +
                this.props.imgClass
              }
            >
              <div className="ie-fix" />
              {/* <div className="homeButton show-mobile" onClick={this.goHome}>
                <img src={require("../../images/home.png")} alt="" />
              </div> */}
              <img
                className="temp1-max-logo hidden-mobile"
                src={require("../../images/max_logo_white.svg")}
                alt=""
              />
            </div>
            <div className="col-md-9 px-0">
              <div className="screen-centered">
                <div className="pt-5 hidden-mobile" />
                {/* <div className="pt-5 show-mobile" /> */}
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  goHome = () => {
    history.push("/rakazut");
  };
}

export default AdminPages;
