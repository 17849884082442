import React, { Component } from "react";
import DatePickerComp, { registerLocale } from "react-datepicker";
import he from "date-fns/locale/he";
import { format, parse } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "./datePicker.css";

registerLocale("he", he);

class DatePicker extends Component {
  state = {
    toggleDate: false,
    chosen: false,
    date: ""
  };
  static getDerivedStateFromProps(nextProp, prevState) {
    if (nextProp.value && nextProp.value !== "") {
      return {
        date: parse(nextProp.value, nextProp.format, new Date()),
        chosen: true
      };
    }
    return {
      date: "",
      chosen: false
    };
  }
  componentDidMount() {
    if (this.props.value && this.props.value !== "") {
      this.setState({
        date: parse(this.props.value, this.props.format, new Date()),
        chosen: true
      });
    }
  }

  render() {
    return (
      <div>
        <div
          className={
            "datePicker-box" + (this.props.rtl ? " rtl-picker" : "ltr-picker")
          }
        >
          <div onClick={this.toggleCalendar} className="datePicker-text">
            {this.state.chosen && format(this.state.date, this.props.format)}
            {!this.state.chosen && this.props.placeholder}
          </div>
          {this.state.chosen && (
            <span className="datePicker-clear" onClick={this.clearDate}>
              <div aria-hidden="true" className="">
                <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                  className="css-tj5bde-Svg"
                >
                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
                </svg>
              </div>
            </span>
          )}
        </div>
        {this.state.toggleDate && (
          <div className={this.props.rtl ? " rtl-picker" : "ltr-picker"}>
            <DatePickerComp
              className="red-border"
              selected={this.state.date}
              onSelect={this.changeDate}
              locale="he"
              withPortal
              fixedHeight
              inline
            />
          </div>
        )}
      </div>
    );
  }
  toggleCalendar = e => {
    e && e.preventDefault();
    this.setState({ toggleDate: !this.state.toggleDate });
  };
  clearDate = e => {
    e && e.preventDefault();
    this.setState({
      chosen: false,
      date: ""
    });
    this.props.change("");
  };
  changeDate = date => {
    this.setState({
      chosen: true,
      date: date
    });
    this.props.change(format(date, this.props.format));
    this.toggleCalendar();
  };
}

export default DatePicker;
