import React, { Component } from "react";
import { /*Router, Route, Switch,*/ Redirect } from "react-router-dom";
import Auth from "utils/auth";
import Api from "utils/api";

class Logout extends Component {
  state = {
    go: true
  };
  componentWillMount() {
    Auth.deleteAuthenticated();
    Api.post("OnBoard/SignOut", {})
      .then(response => {
        const {
          data: { Success } //, Payload, Message, ErrorCode
        } = response;
        if (Success) {
        }
        this.setState({ go: true });
      })
      .catch(err => {
        this.setState({ go: true });
      });
  }
  render() {
    return (
      <React.Fragment>
        {this.state.go ? <Redirect to="/login" /> : null}
      </React.Fragment>
    );
  }
}

export default Logout;
