import React, { Component } from "react";
import LoginTemplate from "components/login-temp/login-temp.jsx";
import Api from "utils/api";
import { Errors } from "utils/const";
class ChangePassword extends Component {
  state = {
    sent: false,
    password: "",
    confirmPassword: ""
  };
  render() {
    return (
      <LoginTemplate>
        <img
          className="max-logo hidden-mobile mb-5"
          src={require("../../images/max_logo_blue.svg")}
          alt=""
        />

        {!this.state.sent ? (
          <React.Fragment>
            <div className="login-text rtl text-center text-blue">
              {this.props.newuser ? (
                <div>
                  עוד שלב קטנטן
                  <br /> קבע סיסמה
                </div>
              ) : (
                <div class="mb-2">לא נורא, קורה לכולם.</div>
              )}
            </div>
            <div className="rtl font-weight-bold text-secondary">
              רק מזכירים ש:
              <div>1. בוחרים סיסמה עם 8 תווים לפחות</div>
              <div>
                2. משלבים בסיסמה אותיות גדולות, אותיות קטנות ומספרים. <br />
                לדוגמה: Max92019, Welcome8, 2Getherr
              </div>
            </div>
            <div className="rtl text-error text-center error-line">
              {this.state.message}
            </div>
            <form className="input-field-container" onSubmit={this.submitLogin}>
              <input
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.change}
                className="input-field mb-1"
                placeholder="סיסמה"
              />
              <input
                type="password"
                name="confirmPassword"
                onChange={this.change}
                value={this.state.confirmPassword}
                className="input-field mb-1"
                placeholder="אשר סיסמה"
              />
              <input
                type="submit"
                className="max-btn max-login-btn mt-5"
                value={this.props.newuser ? "קבע סיסמה" : "החלף סיסמה"}
              />
            </form>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="login-text rtl text-center text-blue">
              {this.props.newuser ? (
                <div>הסיסמה נקבעה בהצלחה</div>
              ) : (
                <div>הסיסמה שונתה בהצלחה</div>
              )}
            </div>
          </React.Fragment>
        )}
      </LoginTemplate>
    );
  }
  /*
  componentDidMount = () => {
    document.getElementById("root").className = "showRecaptcha";
  };
  componentWillUnmount = () => {
    document.getElementById("root").className = "";
  };*/
  change = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  submitLogin = e => {
    e.preventDefault();
    if (this.state.password.trim() === "") {
      this.setState({
        message: Errors.e50
      });
      return;
    } else if (
      !this.state.password.match(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,}$/)
    ) {
      this.setState({
        message: Errors.e8
      });
      return;
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        message: Errors.e51
      });
      return;
    }
    let send = {
      NewPassword: this.state.password,
      Token: this.props.match.params.Token,
      UserID: this.props.match.params.UserId
    };
    Api.post("OnBoard/ChangePasswordAction", send)
      .then(response => {
        const {
          data: { Success /*, Payload*/, Message, ErrorCode }
        } = response;
        if (Success) {
          this.setState({
            sent: true
          });

          setTimeout(() => {
            this.props.history.push("/");
          }, 5000);
        } else {
          this.setState({
            password: "",
            message: Errors["e" + ErrorCode] ? Errors["e" + ErrorCode] : Message
          });
        }
      })
      .catch(() => {});
  };
}

export default ChangePassword;
