import React, { Component } from "react";
import "./story-modal.css";
import { ContentBaseUrl } from "utils/const.js";
import { checkIEorEdge } from "utils/func.js";
class StoryCarousel extends Component {
  state = { showRight: false, showLeft: false };
  render() {
    return (
      <div className="story-wrapper">
        <div
          className="mt-md-5 stories-container rtl"
          ref={(e) => (this.storyContainer = e)}
          onScroll={this.updateArrows}
        >
          {this.props.stories.map((story, index) => {
            return (
              <div
                className="story-item"
                onClick={() => {
                  this.props.openStory(ContentBaseUrl + story.MoviePath);
                  this.props.markViewed(story.Id);
                }}
                key={index}
              >
                <img
                  className={
                    story.Viewed
                      ? "story-img viewed-story"
                      : "story-img unviewed-story"
                  }
                  src={ContentBaseUrl + story.PicturePath}
                  alt=""
                />
                <span className="text-blue font-weight-bold story-title">
                  <div>{story.Name}</div>
                </span>
              </div>
            );
          })}
        </div>
        <button
          onClick={this.scrollLeft}
          // className={
          //   "scroll-left-btn" + (this.state.showLeft ? " shown" : " hidden")
          // }
          className="scroll-left-btn"
        >
          {"<"}
        </button>
        <button
          onClick={this.scrollRight}
          className="scroll-right-btn"
          // className={
          //   "scroll-right-btn" + (this.state.showRight ? " shown" : " hidden")
          // }
        >
          {">"}
        </button>
      </div>
    );
  }
  componentDidMount() {
    this.myObserver = new MutationObserver((entries) => {
      this.updateArrows(); // iterate over the entries, do something.
    });
    this.myObserver.observe(this.storyContainer, {
      // attributes: true,
      childList: true,
    });
  }
  componentWillUnmount() {
    this.myObserver.disconnect();
  }
  updateArrows = () => {
    let maxScroll, scrollLeft;
    if (checkIEorEdge()) {
      maxScroll =
        this.storyContainer.scrollWidth - this.storyContainer.offsetWidth;
      scrollLeft = maxScroll - this.storyContainer.scrollLeft;
    } else {
      maxScroll =
        this.storyContainer.scrollWidth - this.storyContainer.offsetWidth;
      scrollLeft = this.storyContainer.scrollLeft;
    }
    this.setState({
      showRight: scrollLeft !== maxScroll,
      showLeft: scrollLeft > 0, //this.carouselContainer.scrollLeft > 0 ? true : false
    });
  };
  scrollRight = () => {
    if (checkIEorEdge()) this._scrollLeft();
    else this._scrollRight();
  };
  scrollLeft = () => {
    if (checkIEorEdge()) this._scrollRight();
    else this._scrollLeft();
  };
  _scrollRight = () => {
    let container = this.storyContainer;
    let scrollAmount = 0;
    let slideTimer = setInterval(function () {
      container.scrollLeft += 5;
      scrollAmount += 5;
      if (scrollAmount >= 110) {
        window.clearInterval(slideTimer);
      }
    }, 10);
  };

  _scrollLeft = () => {
    let container = this.storyContainer;
    let scrollAmount = 0;
    let slideTimer = setInterval(function () {
      container.scrollLeft -= 5;
      scrollAmount += 5;
      if (scrollAmount >= 110) {
        window.clearInterval(slideTimer);
      }
    }, 10);
  };
}

export default StoryCarousel;
