import React, { Component } from "react";
import { Link } from "react-router-dom";
import Api from "utils/api";
import { format } from "date-fns";
import AdminList from "components/admin-temp/admin-list-template.jsx";
import "./users.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { DateFormat } from "utils/const.js";
import { stringCsv } from "utils/func.js";
import ToggleSwitch from "components/toggle-switch/toggle-switch";
import EditUser from "./edit-user";
import DownloadFile from "utils/filedownload.js";

class Users extends Component {
  constructor(props) {
    super(props);
    this.scrollY = React.createRef();
    this.scrollX = React.createRef();
    this.state = {
      loaded: false,
      roles: [],
      sites: [],
      scrollYvalue: 0,
      maxScrollY: 0,
      scrollXvalue: 0,
      maxScrollX: 0,
      users: [],
      togleEdit: false,
      currentEdit: {
        UserId: "",
        Username: "",
        IdNum: "",
        Email: "",
        Role: "",
        Site: "",
        CreatedOn: "10/10/2005"
      },
      searchText: "",
      activeOnly: false
    };
  }
  render() {
    const titleObject = {
      undefined: "כל המשתמשים",
      all: "כל המשתמשים",
      low: "משתמשים עם אחוז ביצוע נמוך",
      late: "משתמשים שלא סיימו בזמן",
      done: "משתמשים שסיימו"
    };

    const title = titleObject[this.props.type];
    const _users = this.state.users
      .filter(this.activeOnly)
      .filter(this.searchIt);
    const users = _users;
    const railTrackStyle = { backgroundColor: "#071b56" };
    const handleStyleY = {
      backgroundColor: "#8cead5",
      borderColor: "#8cead5",
      width: "30px",
      height: "30px",
      margin: "0px 0px -15px -13px"
    };
    const handleStyleX = {
      backgroundColor: "#8cead5",
      borderColor: "#8cead5",
      width: "20px",
      height: "20px",
      margin: "-8px 0px 0px -10px"
    };
    return (
      <AdminList>
        <div className="show-mobile pb-5"></div>
        <div className="page-title rtl mb-3 font-weight-bold">{title}</div>
        <input
          style={{ borderRadius: "20px", padding: "5px 0px" }}
          type="text"
          className="searchInput text-center"
          placeholder="מחפשים את אחד העובדים? רשמו את השם פה"
          value={this.state.searchText}
          onChange={this.doSearch}
        />
        <div className="activeSec my-4">
          <span className="activeSec-text">משתמשים פעילים בלבד</span>
          <ToggleSwitch
            onPress={() => {
              this.setState({ activeOnly: !this.state.activeOnly });
            }}
            isChecked={this.state.activeOnly}
          />
        </div>
        <div className={"rtl" + (this.state.loaded ? " hidden" : "")}>
          טוען נתונים...
        </div>
        <div
          className={
            "rtl" +
            (!(this.state.loaded && users.length === 0) ? " hidden" : "")
          }
        >
          לא נמצאו משתמשים
        </div>
        <div
          className={
            "rtl table-container" + (users.length === 0 ? " hidden" : "")
          }
          style={{ userSelect: "none" }}
          ref={this.scrollX}
          onScroll={this.scrollItX}
        >
          <div
            className={
              "hidden-mobile " + (this.state.maxScrollY > 0 ? "" : "hidden")
            }
            style={{
              float: "right",
              direction: "ltr",
              margin: "50px 5px 20px 15px",
              width: 20,
              height: 480
            }}
          >
            <Slider
              vertical
              onChange={this.changeY}
              value={this.state.scrollYvalue}
              trackStyle={railTrackStyle}
              railStyle={railTrackStyle}
              handleStyle={handleStyleY}
              min={0}
              max={this.state.maxScrollY}
            />
          </div>
          <table
            className="user-table text-right"
            style={{ userSelect: "none" }}
          >
            <thead>
              <tr className="noBorder">
                <td className="font-weight-bold t-col-1 text-right" />
                <td className="font-weight-bold t-col-2 text-right">שם</td>
                <td className="font-weight-bold t-col-3 text-right">
                  מספר עובד
                </td>
                <td className="font-weight-bold t-col-4 text-right">
                  אחוז ביצוע
                </td>
                <td className="font-weight-bold t-col-5 text-right">
                  ימים לסיום
                </td>
                <td className="font-weight-bold t-col-6 text-right">ת.קליטה</td>
                <td className="font-weight-bold t-col-7 text-right">אגף</td>
              </tr>
            </thead>
            <tbody
              ref={this.scrollY}
              onScroll={this.scrollItY}
              style={{ userSelect: "none" }}
            >
              {users.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className={item.DaysLeft === 0 ? "user-not-active" : ""}
                  >
                    <td className="t-col-1 text-right">
                      <button
                        className="editButton"
                        onClick={() => {
                          this.goEdit(item);
                        }}
                      />
                    </td>
                    <td className="t-col-2 text-right">
                      <Link to={"/rakazut/users/progress/" + item.UserId}>
                        {item.Username}
                      </Link>
                    </td>
                    <td className="t-col-3 text-right">
                      <Link to={"/rakazut/users/progress/" + item.UserId}>
                        {item.IdNum}
                      </Link>
                    </td>
                    <td className="t-col-4 text-right">
                      <Link to={"/rakazut/users/progress/" + item.UserId}>
                        {item.Completed}
                      </Link>
                    </td>
                    <td className="t-col-5 text-right">
                      <Link to={"/rakazut/users/progress/" + item.UserId}>
                        {item.DaysLeft}
                      </Link>
                    </td>
                    <td className="t-col-6 text-right">
                      <Link to={"/rakazut/users/progress/" + item.UserId}>
                        {format(new Date(item.CreatedOn), DateFormat)}
                      </Link>
                    </td>
                    <td className="t-col-7 text-right">
                      <Link to={"/rakazut/users/progress/" + item.UserId}>
                        {item.Site}
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <small style={{ fontSize: "14px" }}>
            *משתמשים עם אחוז ביצוע נמוך יקבלו התראה למייל
          </small>
        </div>
        {!this.props.type && (
          <div className="rtl">
            {/* <span className="user-not-active border border-dark">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            &nbsp; משתמשים אשר סיימו את תקופת ההכשרה */}
          </div>
        )}

        <div
          className={"show-mobile" + (users.length === 0 ? " hidden" : "")}
          style={{
            width: "100%",
            backgroundColor: "transparent",
            position: "sticky",
            bottom: "0px"
          }}
        >
          <div style={{ height: "30px", width: "50%", margin: "0px auto" }}>
            <Slider
              style={{ margin: "20px 0px 20px 0px" }}
              onChange={this.changeX}
              value={this.state.scrollXvalue}
              trackStyle={railTrackStyle}
              railStyle={railTrackStyle}
              handleStyle={handleStyleX}
              min={0}
              max={this.state.maxScrollX}
            />
          </div>
        </div>
        <button
          className={
            "csv-export-button" + (users.length === 0 ? " hidden" : "")
          }
          onClick={this.downloadTheFile}
        >
          ייצא דוח אקסל
        </button>
        <EditUser
          user={this.state.currentEdit}
          open={this.state.togleEdit}
          close={this.closeEdit}
        />
      </AdminList>
    );
  }

  componentDidMount() {
    this.myObserver = new MutationObserver(entries => {
      this.iniScrollers(); // iterate over the entries, do something.
    });
    this.myObserver.observe(this.scrollY.current, { childList: true });
    this.myObserver.observe(this.scrollX.current, { childList: true });
    this.getUsers();
  }
  componentWillUnmount() {
    this.myObserver.disconnect();
  }
  downloadTheFile = () => {
    const users = this.state.users
      .filter(this.activeOnly)
      .filter(this.searchIt);

    DownloadFile(
      `מספר עובד,שם,אחוז ביצוע,ימים לסיום,ת.קליטה,אימייל,אגף` +
        "\n" +
        users
          .map(item => {
            const IdNum = item.IdNum;
            const Username = stringCsv(item.Username);
            const Completed = item.Completed;
            const Email = stringCsv(item.Email);
            const Site = stringCsv(item.Site);
            const DaysLeft = item.DaysLeft;
            const CreatedDate = format(new Date(item.CreatedOn), DateFormat);
            return `${IdNum},="${Username}",${Completed},${DaysLeft},${CreatedDate},="${Email}",="${Site}"`;
          })
          .join("\n"),
      "users.csv",
      "text/csv"
    );
  };
  goEdit = user => {
    this.setState({ togleEdit: true, currentEdit: user });
  };
  doSearch = e => {
    this.setState({
      searchText: e.target.value
    });
  };
  activeOnly = item => {
    if (this.state.activeOnly) return item.Status;
    return true;
  };
  searchIt = item => {
    if (this.state.searchText.trim() === "") return true;
    if (
      item.Username.includes(this.state.searchText) ||
      item.IdNum.includes(this.state.searchText)
    )
      return true;
  };
  closeEdit = updateUsers => {
    this.setState({
      togleEdit: false,
      currentEdit: {
        UserId: "",
        Username: "",
        IdNum: "",
        Email: "",
        Role: null,
        Site: null,
        CreatedOn: "10/10/2005"
      }
    });
    if (updateUsers) {
      this.getUsers();
    }
  };
  iniScrollers = () => {
    const maxY =
      this.scrollY.current.scrollHeight - this.scrollY.current.offsetHeight;
    const valueY = maxY - this.scrollY.current.scrollTop;

    const maxX =
      this.scrollX.current.scrollWidth - this.scrollX.current.offsetWidth;
    const valueX = this.scrollX.current.scrollLeft;
    this.setState({
      maxScrollY: maxY,
      scrollYvalue: valueY,
      maxScrollX: maxX,
      scrollXvalue: valueX
    });
  };
  getUsers = () => {
    const EndUrl = {
      undefined: "?value=&name=&active=false",
      all: "?value=&name=&active=false",
      low: "?value=2&name=&active=false",
      late: "?value=3&name=&active=false",
      done: "?value=1&name=&active=false"
    };
    const url = EndUrl[this.props.type];
    Api.get("/AdminPanel/GetAdminPanel" + url).then(response => {
      const {
        data: { Payload /*, Success, Message, ErrorCode */ }
      } = response;

      this.setState({ loaded: true, users: Payload });
    });
  };
  changeY = value => {
    this.setState({
      scrollYvalue: value
    });
    this.scrollY.current.scrollTop = this.state.maxScrollY - value;
  };
  scrollItY = () => {
    this.setState({
      scrollYvalue: this.state.maxScrollY - this.scrollY.current.scrollTop
    });
  };
  changeX = value => {
    this.setState({
      scrollXvalue: value
    });
    this.scrollX.current.scrollLeft = value;
  };
  scrollItX = () => {
    //console.log("asd");
    this.setState({
      scrollXvalue: this.scrollX.current.scrollLeft
    });
  };
}

export default Users;
