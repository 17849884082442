import React, { Component } from "react";

import Content from "./content";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./contentWrap.css";
import Api from "utils/api";
class ContentWraper extends Component {
  constructor(props) {
    super(props);
    //console.log(props);
    this.state = {
      currentSlide: 0,
      slideLength: 0,
      showNext: true,
      showPrev: false
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const RC = nextProps.content.RefreshContents;
    if (RC && RC.length !== prevState.slideLength)
      return { slideLength: RC.length, currentSlide: RC.length - 1 };
    return { ...prevState };
  }
  goNext = () => {
    this.slider.slickPrev();
  };
  goPrevious = () => {
    this.slider.slickNext();
  };
  updateRefresh = i => {
    const index = this.state.slideLength - 1 - i;
    this.setState({
      showNext: index !== this.state.slideLength - 1,
      showPrev: index !== 0
    });
    if (this.props.admin) return;
    const id = this.props.content.RefreshContents[index].ContentId;
    console.log(id);
    Api.post("/OnBoard/MarkRefreshWatched", {
      ContentId: id
    })
      .then(response => {})
      .catch(() => {});
  };
  render() {
    // console.log(this.props.content);
    /*
    var settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      infinite: false,
      arrows: false,
      speed: 500,
      rtl: true,
      afterChange: this.updateRefresh
    };*/
    return (
      <div className="row mt-5 px-0 mx-0">
        <div className="col-md-8 offset-md-2">
          {this.props.content.RefreshContents &&
          this.props.content.RefreshContents.length > 0 ? (
            <div className="car-wraper">
              <p
                dangerouslySetInnerHTML={{
                  __html: this.props.content.Description
                }}
                className="text-right rtl"
              ></p>
              <React.Fragment>
                {/*this.state.showNext && (
                  <button
                    className="car-next"
                    onClick={() => {
                      this.goNext();
                    }}
                  />
                )}
                {this.state.showPrev && (
                  <button
                    className="car-prev"
                    onClick={() => {
                      this.goPrevious();
                    }}
                  />
                  )*/}
                {/* <Slider {...settings} ref={c => (this.slider = c)}> */}
                {this.props.content.RefreshContents.map((item, index) => {
                  return (
                    <Content
                      refresh
                      key={index}
                      content={item}
                      toggleLiked={this.props.toggleLiked}
                      admin={this.props.admin}
                      adminWatchUser={this.props.adminWatchUser}
                    />
                  );
                })}
                {/* </Slider> */}
              </React.Fragment>
            </div>
          ) : (
            <Content
              content={this.props.content}
              toggleLiked={this.props.toggleLiked}
              admin={this.props.admin}
              adminWatchUser={this.props.adminWatchUser}
            />
          )}
        </div>
      </div>
    );
  }
}
export default ContentWraper;
