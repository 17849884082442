import React, { useRef } from "react";
import AddContentRefresher from "./add-content-refresher";
import ContentRefresherHeaders from "./content-refresher-headers";
import ContentRefresherItem from "./content-refresher-item";
import Modal from "./modal";
import {
  DragDropContext,
  Droppable,
  Draggable,
  onDragEnd,
} from "react-beautiful-dnd";

export default function ContentRefresher({
  catName,
  contentName,
  addContent,
  refresherContent,
  catId,
  contentId,
  setActiveContentRefresher,
  displayOrder,
  editContent,
  setShowRefresher,
  setRefresherContent,
}) {
  const modal = useRef(null);

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) {
      return;
    }
    const items = Array.from(refresherContent);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setRefresherContent(items);
  }

  return (
    <div
      className="user-table text-right"
      style={{
        padding: "1em",
        border: "none",
        borderRadius: "4px",
        backgroundColor: "#f3f3f3",
        marginBottom: "1em",
        boxShadow: "2px 2px 3px rgba(0, 0, 0, 0.3)",
      }}
    >
      <ContentRefresherHeaders />
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="refresherContent">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {refresherContent ? (
                refresherContent.map((item,index) => {
                  return (
                    <Draggable
                      key={item.Id}
                      draggableId={item.Id.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ContentRefresherItem
                            isDragging={snapshot.isDragging}
                            editContent={editContent}
                            active={item.Active}
                            setActiveContentRefresher={
                              setActiveContentRefresher
                            }
                            refresherId={item.Id}
                            catId={catId}
                            contentId={contentId}
                            key={item.Id}
                            name={item.Name}
                            description={item.Description}
                            file={item.LinkTo}
                            image={item.IconPath}
                            time={item.MinutesRequired}
                            displayOrder={item.DisplayOrder}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })
              ) : (
                <div></div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div>
        <button
          className="max-form-btn max-login-btn mt-5"
          style={{ fontSize: "1em" }}
          onClick={() => modal.current.open()}
        >
          הוספת תוכן לתוך הדף
        </button>
        <Modal ref={modal}>
          <AddContentRefresher
            modal={modal}
            displayOrder={displayOrder}
            catId={catId}
            contentId={contentId}
            catName={catName}
            contentName={contentName}
            addContent={addContent}
          />
        </Modal>
      </div>
    </div>
  );
}
