import React, { Component } from "react";
import "./contents.css";
import Api from "utils/api";
import { ContentBaseUrl } from "utils/const.js";
import history from "utils/history";
import { checkIEorEdge } from "utils/func.js";

class ContentCarousel extends Component {
  state = {
    isModalOpen: false,
    showLeft: true,
    showRight: true,
  };
  componentDidMount() {
    const { maxScroll } = this.getMaxAndLeft(); //scrollLeft
    if (!checkIEorEdge()) this.carouselContainer.scrollLeft = maxScroll;
    this.updateArrows();
  }
  openContent = (item) => {
    if (!item.Enabled) {
      this.toggleModal();
      return;
    }
    if (item.Refresh === -1) {
      history.push(
        item.Enabled ? "/rakazut/users/showcontent/" + item.ContentId : "#"
      );
      return;
    }
    this.props.openContent(item);
    //this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  toggleModal = () => {
    this.props.toggleModal();
    //this.setState({ isModalOpen: !this.state.isModalOpen });
  };
  openContent = (item) => {
    if (!item.Enabled) {
      this.toggleModal();
      return;
    }
    if (item.Refresh === -1) {
      history.push(item.Enabled ? "/user/contents/" + item.ContentId : "#");
      return;
    }
    this.props.openContent(item);
    //this.setState({ isModalOpen: !this.state.isModalOpen });
  };
  toggleLiked = (id) => {
    Api.post("/OnBoard/ToggleLikeContent", { ContentId: id })
      .then((response) => {
        /*
        const {
          data: { Success, Payload, Message, ErrorCode }
        } = response;
        */
        this.props.getContents();
      })
      .catch(() => {});
  };

  render() {
    return (
      <h3 className="carousel-category text-right px-3 mb-4">
        <div className="row mt-md-5 rtl">
          <div className="carousel-container">
            <h3 className="carousel-category text-right mb-4">
              {this.props.content.Category}
            </h3>
            <button
              onClick={this.scrollRight}
              className={
                "scroll-left-button text-blue font-3em hidden-mobile"
                // (this.state.showRight ? " shown" : " hidden")
              }
            >
              {"<"}
            </button>
            <button
              onClick={this.scrollLeft}
              className={
                "scroll-right-button text-blue font-3em hidden-mobile"
                // (this.state.showLeft ? " shown" : " hidden")
              }
            >
              {">"}
            </button>
            <div
              className="content-carousel-images"
              ref={(e) => (this.carouselContainer = e)}
              onScroll={this.updateArrows}
            >
              {this.props.content.Contents.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{ cursor: "pointer", position: "relative" }}
                    className={
                      item.Enabled
                        ? "content text-center bg-max-blue"
                        : "content content-disabled text-center bg-max-blue"
                    }
                  >
                    <div
                      onClick={() => {
                        this.openContent(item);
                      }}
                    >
                      <img
                        className="content-image"
                        src={ContentBaseUrl + item.Picture}
                        alt=""
                      />
                      <h3 className="content-title text-white py-md-3 py-md-1">
                        {item.Title}
                      </h3>
                    </div>
                    <div className={item.Enabled ? "Shown" : "hidden"}>
                      <div className="float-right text-white mb-3 mr-3">
                        {item.Watched ? (
                          <img
                            src={require("../../../images/eye.png")}
                            className="eye-img"
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className={item.Enabled ? "shown" : "hidden"}>
                      <div className="float-left text-white mb-3 ml-3">
                        {item.Like ? (
                          <img
                            className="heart-img heart-img-zoom"
                            onClick={() => this.toggleLiked(item.ContentId)}
                            src={require("../../../images/full-heart.png")}
                            alt=""
                          />
                        ) : (
                          <img
                            className="heart-img heart-img-zoom"
                            onClick={() => this.toggleLiked(item.ContentId)}
                            src={require("../../../images/heart.png")}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </h3>
    );
  }
  getMaxAndLeft = () => {
    let maxScroll, scrollLeft;
    if (checkIEorEdge()) {
      maxScroll =
        this.carouselContainer.scrollWidth - this.carouselContainer.offsetWidth;
      scrollLeft = maxScroll - this.carouselContainer.scrollLeft;
    } else {
      maxScroll =
        this.carouselContainer.scrollWidth - this.carouselContainer.offsetWidth;
      scrollLeft = this.carouselContainer.scrollLeft;
    }
    return { maxScroll, scrollLeft };
  };
  updateArrows = () => {
    const { maxScroll, scrollLeft } = this.getMaxAndLeft();
    this.setState({
      showRight: scrollLeft !== maxScroll,
      showLeft: scrollLeft > 0, //this.carouselContainer.scrollLeft > 0 ? true : false
    });
  };
  scrollRight = () => {
    if (checkIEorEdge()) this._scrollLeft();
    else this._scrollRight();
  };
  scrollLeft = () => {
    if (checkIEorEdge()) this._scrollRight();
    else this._scrollLeft();
  };
  _scrollRight = () => {
    let container = this.carouselContainer;
    let scrollAmount = 0;
    let slideTimer = setInterval(function () {
      container.scrollLeft += 5;
      scrollAmount += 5;
      if (scrollAmount >= 200) {
        window.clearInterval(slideTimer);
      }
    }, 10);
  };

  _scrollLeft = () => {
    let container = this.carouselContainer;
    let scrollAmount = 0;
    let slideTimer = setInterval(function () {
      container.scrollLeft -= 5;
      scrollAmount += 5;
      if (scrollAmount >= 200) {
        window.clearInterval(slideTimer);
      }
    }, 10);
  };
}

export default ContentCarousel;
