import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "utils/history";
import Users from "./users/users";
import CreateUser from "./users/create-user";
import Progress from "./users/progress/progress";
import Contents from "./users/contents/contents";
import CreateContent from "./contents/create-content/create-content";
import ContentPage from "./users/contents/content-page";
import AdminPanel from "./admin-panel";
import Page404 from "../404";
import ManageContents from "../manage-contents/manage-contents";
class Admin extends Component {
  render() {
    /*         <Navbar /> */
    return (
      <div>
        <Router history={history}>
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => (
                <Redirect
                  to={{
                    pathname: "/rakazut",
                  }}
                />
              )}
            />
            <Route path="/rakazut" exact render={(props) => <AdminPanel />} />
            <Route
              path="/rakazut/users"
              exact
              render={(props) => <Users {...props} />}
            />
            <Route
              path="/rakazut/users/late"
              exact
              render={(props) => <Users type="late" {...props} />}
            />
            <Route
              path="/rakazut/users/done"
              exact
              render={(props) => <Users type="done" {...props} />}
            />
            <Route
              path="/rakazut/users/low"
              exact
              render={(props) => <Users type="low" {...props} />}
            />
            <Route
              exact
              path="/rakazut/users/contents/:id"
              render={(props) => <Contents {...props} />}
            />
            <Route
              exact
              path="/rakazut/users/contents"
              render={(props) => <Contents admin {...props} />}
            />
            <Route
              exact
              path="/rakazut/users/showcontent/:id/:userId?"
              render={(props) => <ContentPage {...props} />}
            />
            <Route
              exact
              path="/rakazut/users/progress/:id"
              render={(props) => <Progress {...props} />}
            />
            <Route
              path="/rakazut/create-user"
              exact
              render={(props) => <CreateUser {...props} />}
            />
            <Route
              path="/rakazut/create-content"
              exact
              render={(props) => <CreateContent {...props} />}
            />
            <Route
              path="/rakazut/manage-contents"
              exact
              render={(props) => <ManageContents {...props} />}
            />
            <Route render={(props) => <Page404 />} />{" "}
          </Switch>
        </Router>
      </div>
    );
  }
}

export default Admin;
