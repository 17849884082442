const checkIEorEdge = function() {
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;
  return isIE || isEdge;
};
function stringCsv(string) {
  return string.replace(/"/g, '""');
}
export { checkIEorEdge, stringCsv };
