import React, { Component } from "react";
import "./content-page.css";
import Api from "utils/api";
import UserPageTemplate from "components/user-temp/user-pages-template";
import ContentWrap from "components/content/contentWrap";
import nosleep from "../../../utils/nosleep";

class ContentPage extends Component {
  state = {
    content: {},
    contentLength: ""
  };
  render() {
    return (
      <UserPageTemplate noMobile noTop>
        {/* <ContentTop /> */}
        <div className="page-title rtl pt-5">{this.state.content.Title}</div>
        <h5 className="rtl">{this.state.contentLength + " תכנים"}</h5>
        <ContentWrap
          content={this.state.content}
          toggleLiked={this.toggleLiked}
        />
      </UserPageTemplate>
    );
  }

  componentDidMount = () => {
    this.getContent();
    nosleep.enable();
    console.log("no sleep enabled");
  };

  componentWillUnmount = () => {
    nosleep.disable();
    console.log("no sleep disabled");
  };

  markWatched = () => {
    Api.post("/OnBoard/GetContent", { ContentId: this.props.match.params.id })
      .then(response => {
        const {
          data: { Payload /*, Success, Message, ErrorCode*/ }
        } = response;
        // console.log(Payload);
        this.setState({ content: Payload });
      })
      .catch(() => {});
  };

  getContent = () => {
    Api.post("/OnBoard/GetContent", { ContentId: this.props.match.params.id })
      .then(response => {
        const {
          data: { Payload /*,Success,  Message, ErrorCode*/ }
        } = response;
        this.setState({
          content: Payload,
          contentLength: Payload.RefreshContents.length
        });
      })
      .catch(() => {});
  };

  toggleLiked = id => {
    Api.post("/OnBoard/ToggleLikeContent", {
      ContentId: id
    })
      .then(response => {
        /*
        const {
          data: { Success, Payload, Message, ErrorCode }
        } = response;
        */
        this.getContent();
      })
      .catch(() => {});
  };
}
export default ContentPage;
