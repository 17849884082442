import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "utils/history";
import Feed from "./feed/feed";

import Progress from "./progress/progress";
import Contents from "./contents/contents";
import ContentPage from "./contents/content-page";
import Page404 from "../404";
import Help from "./help/help";
class User extends Component {
  render() {
    return (
      <div>
        <Router history={history}>
          <Switch>
            <Route
              path="/"
              exact
              render={props => (
                <Redirect
                  to={{
                    pathname: "/user/feed"
                  }}
                />
              )}
            />
            <Route
              path="/user"
              exact
              render={props => (
                <Redirect
                  to={{
                    pathname: "/user/feed"
                  }}
                />
              )}
            />
            <Route
              path="/user/feed"
              exact
              render={props => <Feed {...props} />}
            />
            <Route
              path="/user/progress"
              exact
              render={props => <Progress {...props} />}
            />
            <Route
              path="/user/contents"
              exact
              render={props => <Contents {...props} />}
            />
            <Route
              path="/user/contents/:id"
              exact
              render={props => <ContentPage {...props} />}
            />
            <Route
              path="/user/help"
              exact
              render={props => <Help {...props} />}
            />
            <Route render={props => <Page404 />} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default User;
