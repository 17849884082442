import React, { Component } from "react";
import "./progress.css";
import Api from "utils/api";
import Slider from "rc-slider";
import { Link } from "react-router-dom";
import AdminPages from "components/admin-temp/admin-pages-template.jsx";
const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return <Slider.Handle {...restProps} className="handle" />; //<Slider.Handle value={value} {...restProps} />;
};
class Progress extends Component {
  state = {
    progress: []
  };
  render() {
    const trackTrackStyle = { backgroundColor: "#231162" };
    const railTrackStyle = { backgroundColor: "#231162" };
    /* 
    const handleStyle = {
      backgroundColor: "#8cead5",
      borderColor: "#8cead5",
      width: "30px",
      height: "30px",
      marginTop: "-12px"
    };
    */
    return (
      <React.Fragment>
        <div className="mobile-vh10" />
        <AdminPages noMobile imgClass="admin-user-progress-img">
          <div
            className="white-bg-mobile"
            style={{
              maxWidth: "800px",
              margin: "0px auto",
              padding: "20px"
            }}
          >
            <div className="page-title mb-4 rtl font-weight-bold">
              {"קצת מספרים על " + this.state.progress.Fullname}
              {this.state.progress.RemainingDays === 0 ? " (לא פעיל)" : null}
            </div>

            <div className="row vh100">
              <div className="col-md-6 offset-md-3 mt-md-5 text-right rtl mobile-order-1">
                <div className="mb-4 stat">
                  <span className="img-wrap">
                    <img
                      className="ml-4"
                      src={require("../../../../images/calendar.png")}
                      width="40"
                      alt=""
                    />
                  </span>
                  {parseInt(this.state.progress.DaysHere, 10) +
                    1 +
                    " ימים נמצא איתנו"}
                </div>
                <div className="stat">
                  <span className="img-wrap">
                    <img
                      className="ml-4"
                      src={require("../../../../images/flag.png")}
                      width="40"
                      alt=""
                    />
                  </span>
                  <Link
                    to={"/rakazut/users/contents/" + this.props.match.params.id}
                  >
                    {this.state.progress.CompletedPer + "% מהתכנים נלמדו"}
                  </Link>

                  <img
                    className="mr-2 crown float-left"
                    src={require("../../../../images/crown.png")}
                    width="40"
                    alt=""
                  />
                  <div
                    style={{
                      pointerEvents: "none"
                    }}
                  >
                    <Slider
                      className="mt-2"
                      value={100 - this.state.progress.CompletedPer}
                      trackStyle={trackTrackStyle}
                      railStyle={railTrackStyle}
                      handle={handle}
                      min={0}
                      max={100}
                    />
                  </div>
                </div>
                <div className="mb-2 stat">
                  <span className="img-wrap">
                    <img
                      className="ml-4"
                      src={require("../../../../images/sandclock.png")}
                      width="40"
                      alt=""
                    />
                  </span>
                  <Link
                    to={"/rakazut/users/contents/" + this.props.match.params.id}
                  >
                    {"יש " +
                      this.state.progress.PendingContents +
                      " תכנים שמחכים"}
                    <img
                      className="ml-4 mr-2 mt-1"
                      style={{ zoom: "1.5" }}
                      src={require("../../../../images/left-arrow.png")}
                      width="40"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
              <div className="col-md-4 text-center text-md-right text-blue font-weight-bold rtl">
                {/* <span className="font-1dot2em d-md-block mx-2 mb-md-3">
                {this.state.progress.Fullname}
              </span> */}
                {/* <span className="font-1dot2em d-md-block mx-2 mb-md-3">
                {this.state.progress.Role}
              </span>
              <span className="font-1dot2em d-md-block mx-2 mb-md-3">
                {this.state.progress.Location}
              </span> */}
                {this.state.progress.RemainingDays === 0 ? (
                  <span className="font-1dot2em d-md-block mx-2 mb-md-3 text-danger">
                    לא פעיל
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </AdminPages>
      </React.Fragment>
    );
  }

  componentDidMount = () => {
    Api.post("/AdminPanel/GetUserAdvance?UserId=" + this.props.match.params.id)
      .then(response => {
        const {
          data: { Payload /*,Success, Message, ErrorCode*/ }
        } = response;
        // console.log(Payload);
        this.setState({
          progress: Payload
        });
      })
      .catch(() => {});
  };
}

export default Progress;
