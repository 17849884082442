import React, { useState } from "react";
import Input from "./input";

export default function AddCatagory({ addCatagory, displayOrder, modal }) {
  const [info, setInfo] = useState({ name: "", displayOrder: displayOrder });

  return (
    <div style={{ backgroundColor: "white", padding: "1em" }}>
      <Input
        value={info.name}
        func={setInfo}
        type={"text"}
        name={"name"}
        label={"שם קטגוריה"}
        data={info}
      />
      <Input
        value={info.displayOrder}
        func={setInfo}
        type={"number"}
        name={"displayOrder"}
        label={"סדר תצוגה"}
        data={info}
      />

      <button
        className="max-form-btn max-login-btn mt-5"
        onClick={() => addCatagory(info.name, info.displayOrder, modal)}
      >
        הוסף
      </button>
    </div>
  );
}
