const EmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const EmailMax = /@max.co.il$/i;
//const EmailRegEx = /^(([^<>()[]\.,;:\\\/\s@"]+(.[^<>()[]\.,;:\\\/\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/;

const ReCaptchaSiteKey = "6Leva8QUAAAAAObxWxw31uL9aHdkpcYVpDe395fF";
const ContentBaseUrl = process.env.REACT_APP_CONTENT_BASE_URL;
const DateFormat = "dd/MM/yyyy";

const Errors = {
  e1: `דוא"ל קיים במערכת`,
  e2: `דוא"ל אינו קיים במערכת`,
  e3: `תאריך אינו תקין`,
  e4: `שם משתמש או סיסמה לא נכונים`,
  e5: `אין הרשאה לפעולה זו`,
  e6: `פעולה נכשלה`,
  e7: `משתמש לא קיים`,
  e8: `סיסמה לא תקינה`,
  e12: `משתמש זה אינו פעיל`,
  e50: `אנא מלא את כל השדות`,
  e51: `סיסמאות לא תואמות`,
  e53: `כתובת דואר אלקטרוני לא תקינה`,
  e54: `כתובת הדואר האלקטרוני אינה שייכת לMAX`,
  e55: `אנא הזן שם מלא (פרטי ומשפחה)`,
  e56: `אנא הזן אגף`,
  e57: `אנא הזן תפקיד`,
  e58: `מספר עובד צריך להכיל רק מספרים`,
  e59: `אנא בחר תאריך קליטה`,
  e98: `תקלת אימות, אנא נסה שנית`,
};
export {
  Errors,
  EmailRegEx,
  ContentBaseUrl,
  DateFormat,
  ReCaptchaSiteKey,
  EmailMax,
};
