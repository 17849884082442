import React from 'react'
import "./toggle-switch.css";


export default function ToggleSwitch({onPress,isChecked}) {
    const handleClick = () => {
        onPress();
      };

    return (
        <label className="switch">
        <input
          className={isChecked ? "checked" : ''}
          type="checkbox"
          onClick={()=>handleClick()}
        />

        <span className="slider round">
          <span className="textOn">On</span>
          <span className="textOff">Off</span>
        </span>
      </label>
    )
}
