import React, { Component } from "react";
import "./contents.css";
import { ContentBaseUrl } from "utils/const.js";
import history from "utils/history";
import { checkIEorEdge } from "utils/func.js";

class AdminContentCarousel extends Component {
  state = {
    showLeft: true,
    showRight: true,
  };

  componentDidMount() {
    const { maxScroll } = this.getMaxAndLeft(); //scrollLeft
    if (!checkIEorEdge()) this.carouselContainer.scrollLeft = maxScroll;
    this.updateArrows();
  }

  toggleModal = () => {
    this.props.toggleModal();
    // this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  toggleLiked = (id) => {};

  openContent = (item) => {
    if (!item.Enabled) {
      this.toggleModal();
      return;
    }
    if (item.Refresh === -1) {
      let url = "/rakazut/users/showcontent/" + item.ContentId;
      if (!this.props.admin) {
        url =
          "/rakazut/users/showcontent/" +
          item.ContentId +
          "/" +
          this.props.userId;
      }
      history.push(item.Enabled ? url : "#");
      return;
    }
    this.props.openContent(item);
    //this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  render() {
    return (
      <h3 className="carousel-category text-right mb-4 px-3">
        <div className="row mt-md-5 rtl">
          <div className="carousel-container">
            <h3 className="carousel-category text-right mb-4">
              {this.props.content.Category}
            </h3>
            <button
              onClick={this.scrollRight}
              className={
                "scroll-left-button text-blue font-3em hidden-mobile"
                // (this.state.showRight ? " shown" : " hidden")
              }
            >
              {"<"}
            </button>
            <button
              onClick={this.scrollLeft}
              className={
                "scroll-right-button text-blue font-3em hidden-mobile"
                // (this.state.showLeft ? " shown" : " hidden")
              }
            >
              {">"}
            </button>
            <div
              className="content-carousel-images"
              ref={(e) => (this.carouselContainer = e)}
              onScroll={this.updateArrows}
            >
              {this.props.content.Contents.map((item, index) => {
                return (
                  <div
                    style={{ position: "relative" }}
                    key={index}
                    className={
                      item.Enabled
                        ? "content text-center bg-max-blue"
                        : "content content-disabled text-center bg-max-blue"
                    }
                  >
                    <div
                      onClick={() => {
                        this.openContent(item);
                      }}
                      key={index}
                    >
                      <img
                        className="content-image"
                        src={ContentBaseUrl + item.Picture}
                        alt=""
                      />
                      <h3 className="content-title text-white py-md-3 py-md-1">
                        {item.Title}
                      </h3>
                    </div>

                    <div
                      className={
                        "content-bottom-text " +
                        (item.Enabled ? "shown" : "hidden")
                      }
                    >
                      <div
                        className={
                          "float-right text-white mb-3 mr-3" +
                          (this.props.admin ? " admin-content-eye" : "")
                        }
                      >
                        {item.Watched || this.props.admin ? (
                          <React.Fragment>
                            <img
                              src={require("../../../../images/eye.png")}
                              className="eye-img"
                              alt=""
                            />
                            {this.props.admin ? (
                              <small
                                style={{
                                  position: "absolute",
                                  bottom: "10px",
                                  right: "32px",
                                }}
                              >
                                &nbsp;{`${item.Watches}/${item.Total}`}&nbsp;
                              </small>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        "content-bottom-text " +
                        (item.Enabled ? "shown" : "hidden")
                      }
                    >
                      <div
                        className={
                          "float-left text-white mb-3 ml-3" +
                          (this.props.admin ? " admin-content-like" : "")
                        }
                      >
                        {item.Like || this.props.admin ? (
                          <React.Fragment>
                            {this.props.admin ? (
                              <span>
                                <img
                                  src={require("../../../../images/full-heart.png")}
                                  className="show-mobile heart-img"
                                  alt=""
                                />
                                <small
                                  style={{
                                    position: "absolute",
                                    bottom: "10px",
                                    left: "30px",
                                  }}
                                >
                                  &nbsp;{`${item.Likes}/${item.Total}`}&nbsp;
                                </small>
                              </span>
                            ) : (
                              ""
                            )}
                            <img
                              src={require("../../../../images/full-heart.png")}
                              className={
                                (this.props.admin ? "hidden-mobile " : "") +
                                "heart-img"
                              }
                              alt=""
                            />
                          </React.Fragment>
                        ) : (
                          <img
                            src={require("../../../../images/heart.png")}
                            className="heart-img"
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </h3>
    );
  }
  getMaxAndLeft = () => {
    let maxScroll, scrollLeft;
    if (checkIEorEdge()) {
      maxScroll =
        this.carouselContainer.scrollWidth - this.carouselContainer.offsetWidth;
      scrollLeft = maxScroll - this.carouselContainer.scrollLeft;
    } else {
      maxScroll =
        this.carouselContainer.scrollWidth - this.carouselContainer.offsetWidth;
      scrollLeft = this.carouselContainer.scrollLeft;
    }
    return { maxScroll, scrollLeft };
  };
  updateArrows = () => {
    const { maxScroll, scrollLeft } = this.getMaxAndLeft();

    this.setState({
      showRight: scrollLeft !== maxScroll,
      showLeft: scrollLeft > 0, //this.carouselContainer.scrollLeft > 0 ? true : false
    });
  };
  scrollRight = () => {
    if (checkIEorEdge()) this._scrollLeft();
    else this._scrollRight();
  };
  scrollLeft = () => {
    if (checkIEorEdge()) this._scrollRight();
    else this._scrollLeft();
  };
  _scrollRight = () => {
    let container = this.carouselContainer;
    let scrollAmount = 0;
    let slideTimer = setInterval(function () {
      container.scrollLeft += 5;
      scrollAmount += 5;
      if (scrollAmount >= 200) {
        window.clearInterval(slideTimer);
      }
    }, 10);
  };

  _scrollLeft = () => {
    let container = this.carouselContainer;
    let scrollAmount = 0;
    let slideTimer = setInterval(function () {
      container.scrollLeft -= 5;
      scrollAmount += 5;
      if (scrollAmount >= 200) {
        window.clearInterval(slideTimer);
      }
    }, 10);
  };
}

export default AdminContentCarousel;
