import React, { useState } from "react";
import Input from "./input";

export default function EditRefresher({
  name,
  description,
  time,
  displayOrder,
  editContent,
  catId,
  contentId,
  refresherId,
  modalEdit,
  active,
}) {
  const [info, setInfo] = useState({
    Name: name,
    CategoryId: catId,
    Description: description,
    StartDays: 1,
    EndDays: 0,
    MinutesRequired: time,
    DisplayOrder: displayOrder,
    FeedDisplayOrder: displayOrder,
    Refresh: contentId,
    Id: refresherId,
    Active: active,
  });

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const change = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "2em",
        display: "flex",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setIsUploading(true);
          editContent(info, file, image, modalEdit, active).then(() =>
            setIsUploading(false)
          );
        }}
      >
        <Input
          value={info.Name}
          func={setInfo}
          type={"text"}
          name={"Name"}
          label={"שם התוכן"}
          data={info}
        />
        <Input
          value={info.Description}
          func={setInfo}
          type={"text"}
          name={"Description"}
          label={"תיאור התוכן"}
          data={info}
          textarea={true}
          notRequired
        />

        <Input
          value={info.MinutesRequired}
          func={setInfo}
          type={"number"}
          name={"MinutesRequired"}
          label={"זמן קריאה"}
          data={info}
        />

        <Input func={setFile} file={true} label={"קובץ"} />

        <button
          type="submit"
          className="max-form-btn max-login-btn mt-5"
          disabled={isUploading}
        >
          {isUploading ? "טוען..." : "הוסף"}
        </button>
      </form>
    </div>
  );
}
