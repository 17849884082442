import React from 'react'

export default function ContentRefresherHeaders() {
    return (
        <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0.5em",
        fontWeight:"bold"
      }}
    >
      <div
        style={{ maxWidth: "30px", minWidth: "30px",marginLeft:"3em"  }}
      ></div>
      <div
        style={{ maxWidth: "150px", minWidth: "150px", marginLeft: "2em" }}
      >
        {" "}
        שם התוכן{" "}
      </div>
      <div style={{minWidth:"72px", maxWidth:"72px", marginLeft:"1em"}}>תיאור</div>
      <div style={{minWidth:"100px", maxWidth:"100px", marginLeft:"1.5em"}}>זמן קריאה</div>
      <div style={{}}>קובץ</div>
      <div style={{}}>פעיל/לא פעיל</div>
    </div>
    )
}
