import React, { Component } from "react";
import "./admin-list-template.css";
import NavBar from "components/navbar/navbar.jsx";
import history from "utils/history.js";

class AdminList extends Component {
  state = {};
  render() {
    return (
      <div>
        <NavBar admin />
        <div className="container-fluid">
          <div className="row my-vh100 justify-content-center">
            {this.props.dontShowMax ? (
              <div></div>
            ) : (
              <div className="col-md-3 bg-teal back-image hidden-mobile-d-flex align-items-center justify-content-center">
                <div className="ie-fix" />
                <img
                  className="temp1-max-logo hidden-mobile"
                  src={require("../../images/max_logo_white.svg")}
                  alt=""
                />
              </div>
            )}
            {/* <div className="minimal-nav show-mobile">
              <img src={require("../../images/max_logo_blue.svg")} alt="" />
              <span onClick={this.goHome} className="close-menu-btn-minimal">
                &times;
              </span>
            </div> */}
            <div className="col-md-9">
              <div className="screen-centered">
                <div className="pt-3 hidden-mobile" />
                {this.props.children}

                {/* <div className="pt-5 show-mobile" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  goHome = () => {
    history.push("/rakazut");
  };
}

export default AdminList;
