import React, { Component } from "react";
import "./toggle-switch.css";

class ToggleSwitch extends Component {
  state = {};
  render() {
    return (
      <label className="switch">
        <input
          className={this.props.isChecked ? "checked" : ""}
          type="checkbox"
          onClick={this.handleClick}
        />

        <span className="slider round">
          <span className="textOn">On</span>
          <span className="textOff">Off</span>
        </span>
      </label>
    );
  }

  handleClick = () => {
    this.props.onPress();
  };
}

export default ToggleSwitch;
