import React, { useRef } from "react";
import EditRefresher from "./edit-refresher";
import edit from "../../images/edit.png";
import Tooltip from "../../components/tooltip/Tooltip";

import Modal from "./modal";
import { ContentBaseUrl } from "utils/const.js";

export default function ContentRefresherItem({
  name,
  description,
  file,
  image,
  time,
  setActiveContentRefresher,
  refresherId,
  contentId,
  catId,
  active,
  editContent,
  displayOrder,
  isDragging,
}) {
  const modal = useRef(null);
  const modalEdit = useRef(null);

  return (
    <div className="content-container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "0.5em",
          boxShadow: "2px 2px 3px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#ece7e7",
          opacity: isDragging ? "0.6" : "1",
          padding: "0.5em",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={() => modalEdit.current.open()}
          style={{
            border: "none",
            width: "fit-content",
            backgroundColor: "transparent",
            maxWidth: "30px",
            minWidth: "30px",
          }}
        >
          <img
            src={edit}
            alt="edit icon"
            style={{ width: "30px", height: "30px", marginLeft: "1.3em" }}
          />
        </button>
        <Modal ref={modalEdit}>
          <EditRefresher
            modalEdit={modalEdit}
            displayOrder={displayOrder}
            name={name}
            description={description}
            refresherId={refresherId}
            time={time}
            currentFile={file}
            currentImage={image}
            catId={catId}
            editContent={editContent}
            contentId={contentId}
            active={active}
          />
        </Modal>
        <div style={{ minWidth: "100px", maxWidth: "100px" }}>
          <Tooltip content={name} direction="bottom">
            {name.length > 10 ? name.substring(0, 7) + "..." : name}
          </Tooltip>
        </div>
        <button
          className="max-form-btn max-login-btn mt-1"
          onClick={() => modal.current.open()}
          style={{
            fontSize: "1em",
          }}
        >
          תיאור
        </button>
        <Modal ref={modal}>
          <div
            style={{
              backgroundColor: "white",
              fontSize: "1.5em",
              padding: "1em",
              paddingTop: "0",
            }}
          >
            {description}
          </div>
        </Modal>

        <div>{time}</div>
        <div>
          <a href={ContentBaseUrl + file} target="_blank">
            קובץ
          </a>
        </div>
        <button
          onClick={() => setActiveContentRefresher(refresherId, active)}
          style={{
            height: "25px",
            width: "25px",
            borderRadius: "50%",
            backgroundColor: !active ? "#f88787" : "lightgreen",
            border: !active ? "5px solid red" : "5px solid green",
          }}
        ></button>
      </div>
    </div>
  );
}
