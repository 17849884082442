import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "utils/history";
import Auth from "utils/auth";

import "./App.css";
import "./index.css";
import Admin from "./pages/admin";
import User from "./pages/user";
import Login from "./pages/login/";
import Logout from "./pages/logout";
import Page404 from "./pages/404";
import Welcome from "./pages/welcome";
import ManageContents from "./pages/manage-contents/manage-contents";
import PasswordPages from "./pages/password-pages/index.jsx";
import Api from "utils/api";
import { ReCaptchaSiteKey } from "utils/const";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
//import { loadReCaptcha } from "react-recaptcha-v3";

const Rec = ({ enable, children }) => {
  if (enable)
    return (
      <GoogleReCaptchaProvider reCaptchaKey={ReCaptchaSiteKey}>
        {children}
      </GoogleReCaptchaProvider>
    );
  return <React.Fragment>{children}</React.Fragment>;
};
class App extends Component {
  state = {
    autoLogin: false,
    reCaptcha: false,
  };
  componentWillMount() {
    //loadReCaptcha(ReCaptchaSiteKey);
    Api.post("OnBoard/AutoLogin", {})
      .then((response) => {
        let recaptcha = true;
        const {
          data: { ErrorCode, Success, Payload }, //, Message, ErrorCode
        } = response;
        if (Success) {
          Auth.setAuthenticated(Payload);
        } else {
          if (ErrorCode == 291) recaptcha = false;
        }

        this.setState({ autoLogin: true, reCaptcha: recaptcha });
      })
      .catch((err) => {
        this.setState({ autoLogin: true });
      });
  }
  render() {
    return (
      <Rec enable={this.state.reCaptcha}>
        {this.state.autoLogin ? (
          <div className="App">
            <Router history={history}>
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) => {
                    if (Auth.isAuthenticated() !== null) {
                      //checj if user or admin
                      if (Auth.isAdmin()) return <Admin {...props} />;
                      else return <User {...props} />;
                    } else {
                      return (
                        <Redirect
                          to={{
                            pathname: "/login",
                            state: { from: props.location },
                          }}
                        />
                      );
                    }
                  }}
                />{" "}
                {/* check */}
                <Route
                  path="/rakazut"
                  render={(props) =>
                    Auth.isAuthenticated() !== null ? (
                      !Auth.isAdmin() ? (
                        <Redirect
                          to={{
                            pathname: "/user",
                            state: { from: props.location },
                          }}
                        />
                      ) : (
                        <Admin {...props} />
                      )
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/login",
                          state: { from: props.location },
                        }}
                      />
                    )
                  }
                />
                <Route
                  path="/welcome"
                  render={(props) =>
                    Auth.isAuthenticated() !== null ? (
                      <Welcome {...props} />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/login",
                          state: { from: props.location },
                        }}
                      />
                    )
                  }
                />
                {/* <Route
                  path="/manage-contents"
                  render={(props) =>
                    Auth.isAuthenticated() !== null ? (
                      <ManageContents {...props} />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/login",
                          state: { from: props.location },
                        }}
                      />
                    )
                  }
                /> */}
                <Route
                  path="/user"
                  render={(props) =>
                    Auth.isAuthenticated() !== null ? (
                      Auth.isAdmin() ? (
                        <Redirect
                          to={{
                            pathname: "/rakazut",
                            state: { from: props.location },
                          }}
                        />
                      ) : (
                        <User {...props} />
                      )
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/login",
                          state: { from: props.location },
                        }}
                      />
                    )
                  }
                />
                <Route
                  path="/login"
                  exact
                  render={(props) =>
                    Auth.isAuthenticated() === null ? (
                      <Login rec={this.state.reCaptcha} {...props} />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/",
                          state: { from: props.location },
                        }}
                      />
                    )
                  }
                />
                <Route
                  path="/password"
                  render={(props) =>
                    Auth.isAuthenticated() === null ? (
                      <PasswordPages {...props} />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/",
                          state: { from: props.location },
                        }}
                      />
                    )
                  }
                />
                <Route
                  path="/logout"
                  exact
                  render={(props) =>
                    Auth.isAuthenticated() !== null ? (
                      <Logout {...props} />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/login",
                          state: { from: props.location },
                        }}
                      />
                    )
                  }
                />
                <Route
                  render={(props) =>
                    Auth.isAuthenticated() !== null ? (
                      <Page404 {...props} />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/login",
                          state: { from: props.location },
                        }}
                      />
                    )
                  }
                />
              </Switch>
            </Router>
          </div>
        ) : null}
      </Rec>
    );
  }
}

export default App;
