import React, { Component } from "react";
import "./image-gallery.css";
import { Link } from "react-router-dom";

class ImageGallery extends Component {
  state = {
    imgIndex: 1
  };
  render() {
    return (
      <div className="ob-container">
        <img
          className="ob-image"
          src={require("../../images/onboarding/" +
            this.state.imgIndex +
            ".jpg")}
          alt=""
        />
        {this.state.imgIndex > 1 ? (
          <span className="ob-prev" onClick={this.goPrev}>
            <img
              width="30px"
              alt=""
              src={require("../../images/arrow-right.png")}
            />
          </span>
        ) : null}
        {this.state.imgIndex < 10 ? (
          <span className="ob-next" onClick={this.goNext}>
            <img
              width="30px"
              alt=""
              src={require("../../images/arrow-left.png")}
            />
          </span>
        ) : (
          <Link className="ob-next" to="/user/feed">
            <button className="ob-button rtl">
              {"לעמוד הבית"}
              <img
                alt=""
                className="ob-button-img"
                width="30px"
                src={require("../../images/arrow-left-blue.png")}
              />
            </button>
          </Link>
        )}
      </div>
    );
  }

  goPrev = () => {
    if (this.state.imgIndex > 1)
      this.setState({ imgIndex: this.state.imgIndex - 1 });
  };

  goNext = () => {
    if (this.state.imgIndex < 10)
      this.setState({ imgIndex: this.state.imgIndex + 1 });
  };
}

export default ImageGallery;
