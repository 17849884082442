import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { createPortal } from "react-dom";
import "./modal.css";

const modalElement = document.getElementById("modal-root");

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export function Modal(
  { children, defaultOpened = false, closeOnClickOutside = true, error },
  ref
) {
  const [isOpen, setIsOpen] = useState(defaultOpened);
  const modalBody = useRef(null);

  useOnClickOutside(
    modalBody,
    () => !error && closeOnClickOutside && setIsOpen(false)
  );

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }),
    []
  );

  const handleEscape = useCallback((event) => {
    if (event.keyCode === 27) setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) document.addEventListener("keydown", handleEscape, false);
    return () => {
      document.removeEventListener("keydown", handleEscape, false);
    };
  }, [handleEscape, isOpen]);

  return createPortal(
    isOpen ? (
      <div className="modal">
        <div className="modal_body" ref={modalBody}>
          <div
            style={{
              backgroundColor: "white",
              paddingLeft: "0.5em",
              paddingTop: "0.5em",
            }}
          >
            {" "}
            <button
              style={{
                border: "none",
                backgroundColor: "white",
                fontSize: "1.3em",
                fontWeight: "bold",
                color: "#231162",
              }}
              onClick={() => setIsOpen(false)}
            >
              X
            </button>
          </div>
          {children}
        </div>
      </div>
    ) : null,
    modalElement
  );
}

export default forwardRef(Modal);
