import React, { useState, useEffect } from "react";
import "./floating-label.css";

export default function Input({
  value,
  func,
  type,
  name,
  label,
  data,
  file,
  textarea,
  refresh,
  refresherEdit,
  notRequired,
}) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (value !== "") setIsActive(true);
    else setIsActive(false);
  }, [value]);

  const change = (e) => {
    func({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const opt = {};
  if (!notRequired) opt["required"] = "required";
  return (
    <div className="float-label" dir="rtl">
      {file ? (
        <input
          className="input-field my-3"
          type="file"
          style={{ paddingTop: "1em" }}
          onChange={(e) => func(e.target.files[0])}
        />
      ) : textarea ? (
        <textarea
          type={type}
          name={name}
          value={value}
          onChange={change}
          className="input-field my-3"
          {...opt}
        />
      ) : refresh ? (
        <input
          type={type}
          name={name}
          checked={value !== 0 ? true : false}
          value={value}
          className="input-field my-3"
          style={{ width: "20px", height: "20px", marginRight: "60px" }}
          onChange={
            refresherEdit
              ? () => {}
              : () => func({ ...data, Refresh: value === 0 ? -1 : 0 })
          }
        />
      ) : (
        <input
          type={type}
          name={name}
          value={value}
          onChange={change}
          className="input-field my-3"
          {...opt}
        />
      )}
      <label className={isActive ? "Active" : ""}> {label}</label>
    </div>
  );
}
