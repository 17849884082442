import React, { Component } from "react";
import "./feed.css";
import Api from "utils/api";

import { ContentBaseUrl } from "utils/const.js";
import StoryModal from "./story/story-modal";
import Navbar from "components/navbar/navbar";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import StoryCarousel from "./story/story-carousel";
import ContentModal from "../contents/content-modal.jsx";
import history from "utils/history";
import noSleep from "../../../utils/nosleep";
import { format } from "date-fns";
import Auth from "utils/auth";
class Feed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stories: [],
      feed: [],
      gotFeed: false,
      storyUrl: "",
      storyOpen: false,
      scrollYvalue: 0,
      maxScrollY: 0,
      showRight: false,
      showLeft: false,
      contentUrl: "",
      contentOpen: false,
    };
    this.scrollY = React.createRef();
  }
  render() {
    const railTrackStyle = { backgroundColor: "#071b56" };
    const serverDate = Auth.lastSeen();
    console.log(serverDate);
    const date =
      serverDate !== null && serverDate !== ""
        ? format(new Date(serverDate), "dd/MM/yyyy HH:mm")
        : "";
    const handleStyleY = {
      backgroundColor: "#8cead5",
      borderColor: "#8cead5",
      width: "30px",
      height: "30px",
      margin: "0px 0px -15px -13px",
    };
    /*const handleStyleX = {
      backgroundColor: "#8cead5",
      borderColor: "#8cead5",
      width: "20px",
      height: "20px",
      margin: "-8px 0px 0px -10px"
    };*/
    return (
      <React.Fragment>
        <Navbar />
        <div
          className="container-fluid"
          style={{ overflow: "hidden !important" }}
        >
          <StoryModal
            ref={(e) => (this.storyVideo = e)}
            isOpen={this.state.storyOpen}
            videoUrl={this.state.storyUrl}
            closeStory={this.closeStory}
          />
          <ContentModal
            isOpen={this.state.contentOpen}
            contentUrl={this.state.contentUrl}
            closeContent={this.closeContent}
          />

          <div className="row my-vh100">
            <div className="col-md-3 bg-teal hidden-mobile-d-flex align-items-center justify-content-center">
              <div className="ie-fix" />
              <img
                className="temp1-max-logo hidden-mobile"
                src={require("../../../images/max_logo_white.svg")}
                alt=""
              />
            </div>

            <div className="col-md-9 feed-box">
              <div className="new-feed-container screen-centered">
                {serverDate != null && serverDate !== "" && (
                  <div
                    className="show-mobile text-center"
                    style={{
                      color: "gray",
                      margin: "-20px 0px 10px 0px",
                    }}
                  >
                    התחברת לאחרונה {date}
                  </div>
                )}
                <img
                  src={require("../../../images/max_logo_blue.svg")}
                  className="max-logo-small hidden-mobile"
                  alt=""
                />
                {serverDate != null && serverDate !== "" && (
                  <div
                    className="hidden-mobile text-center"
                    style={{
                      color: "gray",
                      margin: "15px 0px -15px 0px",
                    }}
                  >
                    התחברת לאחרונה {date}
                  </div>
                )}
                <StoryCarousel
                  className="new-story-carousel"
                  openStory={this.openStory}
                  markViewed={this.markViewed}
                  stories={this.state.stories}
                />
              </div>
              {/* Feed items */}
              <div
                className={
                  "big-text-blue" +
                  (this.state.feed.length !== 0 || !this.state.gotFeed
                    ? " hidden"
                    : "")
                }
              >
                סיימת הכל!
                <br /> נעדכן אותך כאשר יפתחו עוד תכנים
              </div>
              <div
                className={
                  "feed-sec" + (this.state.feed.length === 0 ? " hidden" : "")
                }
              >
                <div
                  className={
                    "hidden-mobile" +
                    (this.state.maxScrollY > 0 ? "" : " hidden")
                  }
                  style={{
                    float: "right",
                    direction: "ltr",
                    margin: "30px 0px 20px 0px",
                    width: "20px",
                    height: "450px",
                  }}
                >
                  <Slider
                    vertical
                    onChange={this.changeY}
                    value={this.state.scrollYvalue}
                    trackStyle={railTrackStyle}
                    railStyle={railTrackStyle}
                    handleStyle={handleStyleY}
                    min={0}
                    max={this.state.maxScrollY}
                  />
                </div>
                <div
                  className={"feed-container px-2"}
                  ref={this.scrollY}
                  onScroll={this.scrollItY}
                >
                  {this.state.feed.map((item, index) => {
                    return (
                      <div
                        // Add the picture path here
                        key={index}
                        className={
                          "feed-content-container " +
                          (index % 2 ? "feed-light-blue" : "feed-blue")
                        }
                      >
                        <div
                          onClick={() => {
                            this.openContent(item);
                          }}
                        >
                          <img
                            className="content-img"
                            src={ContentBaseUrl + item.PicturePath}
                            alt=""
                          />
                        </div>
                        <div className="feed-category">{item.Category}</div>
                        <span className="feed-text float-left m-3">
                          {item.Liked ? (
                            <img
                              className="heart-image"
                              onClick={() => this.toggleLiked(item.ContentId)}
                              src={require("../../../images/full-heart.png")}
                              width="15px"
                              alt=""
                            />
                          ) : (
                            <img
                              className="heart-image"
                              onClick={() => this.toggleLiked(item.ContentId)}
                              src={require("../../../images/heart.png")}
                              width="15px"
                              alt=""
                            />
                          )}
                        </span>
                        <div
                          onClick={() => {
                            this.openContent(item);
                          }}
                        >
                          <div className="content-title rtl feed-text">
                            {item.Name}
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            this.openContent(item);
                          }}
                          className="feed-text content-description rtl"
                          dangerouslySetInnerHTML={{ __html: item.Description }}
                        ></div>
                        <div className="content-footer">
                          <span className="feed-text">
                            כמה זמן זה ייקח לך:{" "}
                          </span>
                          <span className="feed-other-text">
                            <span>{"כ- " + item.Time + " דקות"}</span>
                            <span>{" | "}</span>
                            <span>{item.Completed ? "בוצע" : "לא בוצע"}</span>
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  componentDidMount = () => {
    this.myObserver = new MutationObserver((entries) => {
      this.iniScrollers(); // iterate over the entries, do something.
    });
    this.myObserver.observe(this.scrollY.current, {
      // attributes: true,
      childList: true,
    });
    this.getFeed();
  };
  componentWillUnmount() {
    this.myObserver.disconnect();
  }
  openStory = (url) => {
    noSleep.enable();
    console.log("no sleep enabled");

    this.setState({
      storyUrl: url,
      storyOpen: true,
      contentUrl: "",
      contentOpen: false,
    });
    // this.storyVideo.load();
    // console.log(this.storyVideo);
  };
  iniScrollers = () => {
    const maxY =
      this.scrollY.current.scrollHeight - this.scrollY.current.offsetHeight;
    const valueY = maxY - this.scrollY.current.scrollTop;

    this.setState({
      maxScrollY: maxY,
      scrollYvalue: valueY,
    });
  };
  changeY = (value) => {
    this.setState({
      scrollYvalue: value,
    });
    this.scrollY.current.scrollTop = this.state.maxScrollY - value;
  };
  scrollItY = () => {
    this.setState({
      scrollYvalue: this.state.maxScrollY - this.scrollY.current.scrollTop,
    });
  };
  markViewed = (id) => {
    Api.post("/OnBoard/MarkStoryViewed", { Id: id })
      .then((response) => {
        /*
        const {
          data: { Success, Payload, Message, ErrorCode }
        } = response;
        */
        this.getFeed();
      })
      .catch(() => {});
  };

  closeStory = () => {
    this.setState({ storyUrl: "", storyOpen: false });
    noSleep.disable();
    console.log("no sleep disabled");
  };

  getFeed = () => {
    Api.post("/OnBoard/GetFeed")
      .then((response) => {
        const {
          data: { Payload /*,Success,  Message, ErrorCode*/ },
        } = response;
        // console.log(Payload);
        this.setState({
          gotFeed: true,
          stories: Payload.GeneralMovies,
          feed: Payload.Feed,
        });
      })
      .catch(() => {});
  };

  toggleLiked = (id) => {
    Api.post("/OnBoard/ToggleLikeContent", { ContentId: id })
      .then((response) => {
        /*
        const {
          data: { Success, Payload, Message, ErrorCode }
        } = response;
        */
        this.getFeed();
      })
      .catch(() => {});
  };
  openContent = (item) => {
    noSleep.enable();

    this.setState({ storyUrl: "", storyOpen: false });
    if (item.Refresh === -1) {
      history.push("/user/contents/" + item.ContentId);
      return;
    }

    this.setState({ contentUrl: item.MoviePath, contentOpen: true });
    Api.post("/OnBoard/GetContent", { ContentId: item.ContentId })
      .then((response) => {
        /*const {
          data: { Payload ,
             Success, 
             Message, 
             ErrorCode }
        } = response;*/
        // console.log(Payload);
      })
      .catch(() => {});
    // this.storyVideo.load();
    // console.log(this.storyVideo);
  };
  closeContent = () => {
    this.setState({ contentUrl: "", contentOpen: false });
    this.getFeed();
    noSleep.disable();
  };
}

export default Feed;
