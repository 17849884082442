import React, { Component } from "react";
import UserPageTemplate from "components/user-temp/user-pages-template";
import Auth from "utils/auth";
//import { Router, Route, Switch, Redirect } from "react-router-dom";
//import Auth from "utils/auth";
class Page404 extends Component {
  render() {
    console.log(Auth.isAdmin());
    return (
      <UserPageTemplate
        admin={Auth.isAdmin()}
        noMobile
        imgClass="user-progress-img"
      >
        <div className="page-title mt-5">אופס! תוכן זה לא קיים</div>
      </UserPageTemplate>
    );
  }
}

export default Page404;
