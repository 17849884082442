import React, { useRef, useState } from "react";
import AddContent from "./add-content";
import Content from "./content";
import ContentHeaders from "./content-headers";
import Modal from "./modal";
import EditCategory from "./edit-category";
import editImg from "../../images/edit.png";
import {
  DragDropContext,
  Droppable,
  Draggable,
  onDragEnd,
} from "react-beautiful-dnd";
export default function Catagory({
  name,
  addContent,
  content,
  id,
  setActiveContent,
  setActiveContentRefresher,
  getCategories,
  edit,
  displayOrder,
  active,
  setActiveCategory,
  editCategory,
  setContent,
  setRefresherContent,
}) {
  const modal = useRef(null);
  const editModal = useRef(null);

  const [search, setSearch] = useState("");
  const [showRefresher, setShowRefresher] = useState(true);

  function handleOnDragEnd(result) {
    setShowRefresher(true);
    if (!result.destination) return;
    if (result.destination.index === result.source.index) {
      return;
    }
    const items = Array.from(content);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setContent(items);
  }

  const doSearch = (e) => {
    setSearch(e.target.value);
  };

  const searchIt = (content) => {
    if (search.trim() === "") return true;
    if (content.Name.includes(search)) return true;
  };

  const _content = content.filter(searchIt);
  const filteredContent = _content;

  return (
    <div
      style={{
        marginBottom: "1em",
        boxShadow: "2px 2px 3px rgba(0, 0, 0, 0.3)",
      }}
    >
      <div
        style={{
          backgroundColor: "#d8d7d7",
          padding: "0.3em 0.5em",
          display: "flex",
          direction: "rtl",
          justifyContent: "space-between",
          fontWeight: "bold",
          color: "#231162",
          fontSize: "2em",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            onClick={() => setActiveCategory(id, active)}
            style={{
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              backgroundColor: !active ? "#f88787" : "lightgreen",
              border: !active ? "5px solid red" : "5px solid green",
              marginLeft: "0.5em",
            }}
          />
          <div style={{ marginLeft: "1em", marginBottom: "0.3em" }}>
            <button
              onClick={() => editModal.current.open()}
              style={{
                border: "none",
                maxWidth: "25px",
                minWidth: "25px",
                backgroundColor: "transparent",
              }}
            >
              <img
                src={editImg}
                alt="edit icon"
                style={{ width: "25px", height: "25px" }}
              />
            </button>
            <Modal ref={editModal}>
              <EditCategory
                id={id}
                active={active}
                name={name}
                modal={editModal}
                displayOrder={displayOrder}
                editCategory={editCategory}
              />
            </Modal>
          </div>
          <div style={{ padding: "0", margin: "0", paddingBottom: "0.3em" }}>
            {" "}
            {name}
          </div>
        </div>
      </div>
      <div
        className={"rtl table-container"}
        style={{
          userSelect: "none",
          minWidth: "100%",
          backgroundColor: "rgba(233, 231, 231, 0.363)",
        }}
      >
        <div
          className="user-table text-right"
          style={{
            userSelect: "none",
          }}
        >
          <input
            style={{
              padding: "5px 0px",
              fontSize: "1em",
              width: "100%",
              border: "1px solid rgba(233, 231, 231, 0.363)",
            }}
            type="text"
            placeholder="חפש תוכן ספציפי"
            className="searchInput text-center"
            value={search}
            onChange={doSearch}
          />
          <ContentHeaders />
          <DragDropContext
            onBeforeCapture={() => setShowRefresher(false)}
            onDragEnd={handleOnDragEnd}
          >
            <Droppable droppableId="mainList">
              {(provided) => (
                <div
                  style={{
                    userSelect: "none",
                    padding: "1em",
                    display: "block",
                    width: "100%",
                    maxHeight: "500px",
                    overflow: "auto",
                  }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {filteredContent.map((item,index) => {
                    return (
                      <Draggable
                        key={item.Id}
                        draggableId={item.Id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <Content
                              setRefresherContent={setRefresherContent}
                              showRefresher={showRefresher}
                              setShowRefresher={setShowRefresher}
                              provided={provided}
                              isDragging={snapshot.isDragging}
                              displayOrder={index}
                              editContent={edit}
                              contentId={item.Id}
                              catId={id}
                              key={item.Id}
                              name={item.Name}
                              description={item.Description}
                              start={item.StartDays + 1}
                              end={item.EndDays}
                              time={item.MinutesRequired}
                              file={item.LinkTo}
                              image={item.IconPath}
                              refresher={item.Refresh}
                              active={item.Active}
                              addContent={addContent}
                              refresherContent={item.InnerContents}
                              setActiveContent={setActiveContent}
                              setActiveContentRefresher={
                                setActiveContentRefresher
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          backgroundColor: "rgba(233, 231, 231, 0.363)",
          padding: "1em",
        }}
      >
        <button
          className="max-form-btn max-login-btn mt-3"
          onClick={() => modal.current.open()}
        >
          הוספת תוכן
        </button>
      </div>
      <Modal ref={modal}>
        <AddContent
          modal={modal}
          displayOrder={content.length}
          getCategories={getCategories}
          catId={id}
          name={name}
          addContent={addContent}
        />
      </Modal>
    </div>
  );
}
