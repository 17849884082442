const DownloadFile = function(conetnt, name, type) {
  if (navigator.msSaveBlob) {
    //ie11 fix
    let blob = new Blob(["\uFEFF" + conetnt], {
      type: type
    });
    navigator.msSaveBlob(blob, name);
  } else {
    const element = document.createElement("a");
    const file = new Blob(["\uFEFF" + conetnt], {
      type: type
    });
    element.href = URL.createObjectURL(file);
    element.download = name;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    element.remove();
  }
};
export default DownloadFile;
