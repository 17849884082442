import React, { Component } from "react";
import "./user-pages-template.css";
import NavBar from "components/navbar/navbar.jsx";
import history from "utils/history.js";

class UserPageTemplate extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        {this.props.admin ? <NavBar admin /> : <NavBar />}
        <div className="container-fluid">
          <div className="row my-vh100">
            <div
              className={
                "col-md-3 bg-teal back-image d-flex align-items-center justify-content-center " +
                this.props.imgClass +
                (this.props.noTop ? " hidden-mobile" : "")
              }
            >
              <div className="ie-fix" />
              {/* <div className="homeButton show-mobile" onClick={this.goHome}>
                <img src={require("../../images/home.png")} alt="" />
              </div> */}
              <img
                className="temp1-max-logo hidden-mobile"
                src={require("../../images/max_logo_white.svg")}
                alt=""
              />
            </div>

            <div className="col-md-9 px-0">
              <div className="screen-centered">
                <div className="pt-5 hidden-mobile" />
                {/* <div className="pt-2 show-mobile" /> */}
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  goHome = () => {
    if (this.props.admin) history.push("/rakazut");
    else history.push("/user");
  };
}

export default UserPageTemplate;
