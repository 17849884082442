import React, { useRef, useState } from "react";
import ContentRefresher from "./content-refresher";
import Edit from "./edit";
import edit from "../../images/edit.png";
import Modal from "./modal";
import { ContentBaseUrl } from "utils/const.js";
import arrow from "../../images/arrow-down.png";
import "./refresher.css";
import Tooltip from "../../components/tooltip/Tooltip";
export default function Content({
  name,
  description,
  start,
  end,
  time,
  file,
  image,
  addContent,
  refresherContent,
  catId,
  contentId,
  refresher,
  active,
  setActiveContent,
  editContent,
  displayOrder,
  isDragging,
  setShowRefresher,
  showRefresher,
  provided,
  setRefresherContent,
}) {
  const modal = useRef(null);
  const modalEdit = useRef(null);

  const [showContent, setShowContent] = useState(false);

  return (
    <div>
      <div
        {...provided.dragHandleProps}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "0.5em",
          boxShadow: "2px 2px 3px rgba(0, 0, 0, 0.3)",
          padding: "0.5em",
          backgroundColor: "#eceaea",
          opacity: isDragging ? "0.6" : "1",
        }}
      >
        <div>
          {refresher ? (
            <button
              onClick={() => setShowContent(!showContent)}
              style={{
                border: "none",
                backgroundColor: "transparent",
                marginLeft: "0.5em",
              }}
            >
              <img
                className={showContent ? "arrow" : "arrow-open"}
                src={arrow}
                alt="edit icon"
                style={{ width: "20px", height: "20px" }}
              />
            </button>
          ) : (
            <div></div>
          )}
          <button
            onClick={() => modalEdit.current.open()}
            style={{
              border: "none",
              maxWidth: "30px",
              minWidth: "30px",
              backgroundColor: "transparent",
            }}
          >
            <img
              src={edit}
              alt="edit icon"
              style={{ width: "30px", height: "30px" }}
            />
          </button>
        </div>
        <Modal ref={modalEdit}>
          <Edit
            modalEdit={modalEdit}
            displayOrder={displayOrder}
            name={name}
            description={description}
            start={start}
            end={end}
            time={time}
            currentFile={file}
            currentImage={image}
            catId={catId}
            editContent={editContent}
            contentId={contentId}
            refresher={refresher}
            active={active}
          />
        </Modal>

        <div
          style={{
            maxWidth: "125px",
            minWidth: "125px",
            marginRight: refresherContent ? "-1.7em" : "0",
          }}
        >
          <Tooltip content={name} direction="bottom">
            {name.length > 10 ? name.substring(0, 7) + "..." : name}
          </Tooltip>
        </div>

        <button
          className="max-form-btn max-login-btn mt-1"
          onClick={() => modal.current.open()}
          style={{
            fontSize: "1em",
          }}
        >
          תיאור
        </button>
        <Modal ref={modal}>
          <div
            style={{
              backgroundColor: "white",
              fontSize: "1.5em",
              padding: "1em",
              paddingTop: "0",
            }}
          >
            {description}
          </div>
        </Modal>
        <div style={{ maxWidth: "20px", minWidth: "20px" }}>{start}</div>
        <div style={{ maxWidth: "20px", minWidth: "20px" }}>{end}</div>
        <div style={{ maxWidth: "20px", minWidth: "20px" }}>{time}</div>
        {refresher ? (
          <div
            style={{
              backgroundColor: "rgb(255 242 180)",
              padding: "0.1em 0.5em",
              boxShadow: "2px 2px 3px rgba(0, 0, 0, 0.3)",

              minWidth: "100px",
              maxWidth: "100px",
              textAlign: "center",
            }}
          >
            דף
          </div>
        ) : (
          <div style={{ minWidth: "100px", maxWidth: "100px" }}>
            <a
              style={{ minWidth: "100px", maxWidth: "100px" }}
              href={ContentBaseUrl + file}
              target="_blank"
              rel="noopener noreferrer"
            >
              קובץ
            </a>
          </div>
        )}
        {
          <div>
            <button
              onClick={() => setActiveContent(contentId, active)}
              style={{
                height: "25px",
                width: "25px",
                borderRadius: "50%",
                backgroundColor: !active ? "#f88787" : "lightgreen",
                border: !active ? "5px solid red" : "5px solid green",
                marginLeft: "1em",
              }}
            />
          </div>
        }
      </div>
      {refresher ? (
        showRefresher && showContent ? (
          <ContentRefresher
            setRefresherContent={setRefresherContent}
            setShowRefresher={setShowRefresher}
            editContent={editContent}
            displayOrder={refresherContent.length}
            catId={catId}
            contentId={contentId}
            addContent={addContent}
            contentName={name}
            refresherContent={refresherContent}
            setActiveContentRefresher={setActiveContent}
          />
        ) : (
          <div></div>
        )
      ) : (
        <div></div>
      )}
    </div>
  );
}
