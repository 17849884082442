function isAuthenticated() {
  return sessionStorage.getItem("auth");
}
function setAuthenticated(user) {
  sessionStorage.setItem("auth", JSON.stringify(user));
}

function deleteAuthenticated() {
  sessionStorage.removeItem("auth");
}
function isAdmin() {
  // return true;
  const user = JSON.parse(sessionStorage.getItem("auth"));
  return user.IsAdmin;
}
function lastSeen() {
  // return true;
  const user = JSON.parse(sessionStorage.getItem("auth"));
  return user.LastSeen || "";
}
const auth = {
  isAuthenticated,
  setAuthenticated,
  deleteAuthenticated,
  isAdmin,
  lastSeen
};

export default auth;
