import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./login.css";
import LoginTemplate from "components/login-temp/login-temp.jsx";
import Api from "utils/api";
import Auth from "utils/auth";
import { Errors } from "utils/const";
//simport { ReCaptcha } from "react-recaptcha-v3";

//import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
class Login extends Component {
  state = {
    username: "",
    password: "",
    message: "",
    reCaptchaToken: ""
  };
  render() {
    return (
      <LoginTemplate>
        <img
          className="max-logo hidden-mobile mb-5"
          src={require("../../images/max_logo_blue.svg")}
          alt=""
        />
        <div className="login-text rtl text-center text-blue">
          כמה טוב שבאת!
        </div>
        {/* {sessionStorage.getItem("session_expired") == "true" ? (
          <div class="text-danger font-weight-bold rtl">
            זיהינו כי לא הייתה פעילות ברבע שעה האחרונה
            <br />
            רוצה להמשיך? צריך להתחבר מחדש
          </div>
        ) : (
          ""
        )} */}
        <div
          className="rtl text-error text-center error-line"
          dangerouslySetInnerHTML={{ __html: this.state.message }}
        ></div>
        <form className="input-field-container" onSubmit={this.submitLogin}>
          {/*
           <ReCaptcha
            sitekey={ReCaptchaSiteKey}
            action="login"
            verifyCallback={this.verifyCallback}
            ref={ref => (this.recaptcha = ref)}
          />         
          
          <GoogleReCaptcha action="login" onVerify={this.verifyCallback} />
          */}
          <input
            type="text"
            name="username"
            autoComplete="username"
            value={this.state.username}
            onChange={this.change}
            className={
              this.state.username.length === 0
                ? "input-field my-3 text-right ltr"
                : "input-field my-3 text-left ltr"
            }
            placeholder="שם משתמש"
          />
          <input
            type="password"
            name="password"
            autoComplete="current-password"
            value={this.state.password}
            onChange={this.change}
            className={
              this.state.password.length > 0
                ? "input-field mb-1 text-left ltr"
                : "input-field mb-1 text-right ltr"
            }
            placeholder="סיסמה"
          />
          <NavLink to="/password/forgot">
            <div className="text-left mb-3 text-blue font-weight-bold">
              אוי, שכחתי
            </div>
          </NavLink>
          <div
            className="hidden-mobile rtl text-center"
            style={{ fontSize: "18px", color: "#808080", fontWeight: "bolder" }}
          >
            'max למתחילים' מיועד לעובדים חדשים
            <br />
            בחודש הראשון לעבודתם.
            <br />
            *ליתר העובדים לא מתאפשרת כניסה לאתר
          </div>
          <div
            className="show-mobile rtl text-center"
            style={{ fontSize: "15px", color: "#808080", fontWeight: "bolder" }}
          >
            'max למתחילים' מיועד לעובדים חדשים
            <br />
            בחודש הראשון לעבודתם.
            <br />
            *ליתר העובדים לא מתאפשרת כניסה לאתר
          </div>
          <input
            disabled={false && this.state.reCaptchaToken === ""}
            type="submit"
            className="max-btn max-login-btn mt-5 logindisable"
            value="מתחילים כאן"
          />
        </form>
      </LoginTemplate>
    );
  }
  componentDidMount = () => {
    document.getElementsByTagName("BODY")[0].className = "showRecaptcha";

    if (this.props.location.state && this.props.location.state.expired) {
      this.setState({
        message:
          "זיהינו כי לא הייתה פעילות ברבע שעה האחרונה.<br/> רוצה להמשיך? צריך להתחבר מחדש"
      });
    }
  };
  componentWillUnmount = () => {
    document.getElementsByTagName("BODY")[0].className = "";
  };
  /*refreshCaptcha = () => {
    this.recaptcha.execute();
    this.setState({
      reCaptchaToken: ""
    });
  };
  verifyCallback = token => {
    this.setState({
      reCaptchaToken: token
    });
  };*/

  change = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  submitLogin = async e => {
    sessionStorage.removeItem("session_expired");

    e.preventDefault();

    const token = this.props.rec
      ? await this.props.googleReCaptchaProps.executeRecaptcha("login")
      : "";
    let send = {
      Username: this.state.username,
      Password: this.state.password,
      reCaptchaToken: token
    };
    Api.post("OnBoard/GetLogin", send)
      .then(response => {
        const {
          data: { Success, Payload, Message, ErrorCode }
        } = response;
        if (Success) {
          Auth.setAuthenticated(Payload);
          if (!Payload.Welcome) {
            this.props.history.push("/user/help");
          } else if (
            this.props.location.state &&
            this.props.location.state.expired
          )
            this.props.history.push(this.props.location.state.from.pathname);
          else this.props.history.push("/");
        } else {
          this.setState({
            username: "",
            password: "",
            reCaptchaToken: "",
            message: Errors["e" + ErrorCode] ? Errors["e" + ErrorCode] : Message
          });
          //this.recaptcha.execute();
        }
      })
      .catch(() => {});
  };
}

export default withGoogleReCaptcha(Login);
