import React, { Component } from "react";
import Api from "utils/api.js";

import DatePicker from "components/datePicker/datePicker.jsx";
import AdminPages from "components/admin-temp/admin-pages-template.jsx";
//import "./create-user.css";
import { EmailRegEx, EmailMax, DateFormat, Errors } from "utils/const.js";

class CreateUser extends Component {
  state = {
    newContent: {
      Name: "",
      StartDays: "",
      EndDays: "",
      CategoryId: "",
      Description: "",
      MinutesRequired: "",
      Refresh: "",
      DisplayOrder: "",
      FeedDisplayOrder: "",
    },
    sentTo: "",
    sent: false,
    toggleDate: false,
    roles: [],
    sites: [],
    message: "",
  };
  render() {
    /*
    const roles = this.state.roles.map(item => ({
      label: item.Value,
      value: item.Key
    }));
    const rolePick = roles.find(item => item.value === this.state.newuser.role);
*/
    /*
    const sites = this.state.sites.map(item => ({
      label: item.Value,
      value: item.Key
    }));
    const sitePick = sites.find(item => item.value === this.state.newuser.site);
*/
    return (
      <AdminPages
        imgClass={!this.state.sent ? "admin-add-user-img" : "admin-user-added"}
      >
        {!this.state.sent && (
          <div>
            <div className="page-text rtl my-4 text-center text-blue">
              פרטים טכניים שחייבים <br /> למלא בשביל לצרף את העובד/ת
            </div>
            <div className="rtl text-error text-center error-line">
              {this.state.message}
            </div>
            <form
              className="input-field-container-create-user"
              onSubmit={this.submitLogin}
            >
              <input
                type="file"
                name="Name"
                ref={(file) => {
                  this.ImageFile = file;
                }}
                className="input-field my-3"
                placeholder="שם התוכן"
              />
              <input
                type="file"
                name="Name"
                ref={(file) => {
                  this.ContentFile = file;
                }}
                className="input-field my-3"
                placeholder="שם התוכן"
              />
              <input
                type="text"
                name="Name"
                value={this.state.newContent.Name}
                onChange={this.change}
                className="input-field my-3"
                placeholder="שם התוכן"
              />
              <input
                type="number"
                name="StartDays"
                value={this.state.newContent.StartDays}
                onChange={this.change}
                className="input-field my-3"
                placeholder="יום התחלה"
              />
              <input
                type="number"
                name="EndDays"
                value={this.state.newContent.EndDays}
                onChange={this.change}
                className="input-field my-3"
                placeholder="יום סיום"
              />
              <input
                type="number"
                name="CategoryId"
                value={this.state.newContent.CategoryId}
                onChange={this.change}
                className="input-field my-3"
                placeholder="קטגוריה"
              />
              <input
                type="text"
                name="Description"
                value={this.state.newContent.Description}
                onChange={this.change}
                className="input-field my-3"
                placeholder="תיאור תוכן"
              />

              <input
                type="number"
                name="MinutesRequired"
                value={this.state.newContent.MinutesRequired}
                onChange={this.change}
                className="input-field my-3"
                placeholder="זמן קריאה"
              />

              <input
                type="number"
                name="Refresh"
                value={this.state.newContent.Refresh}
                onChange={this.change}
                className="input-field my-3"
                placeholder="דף"
              />

              <input
                type="number"
                name="DisplayOrder"
                value={this.state.newContent.DisplayOrder}
                onChange={this.change}
                className="input-field my-3"
                placeholder="סדר"
              />
              <input
                type="number"
                name="FeedDisplayOrder"
                value={this.state.newContent.FeedDisplayOrder}
                onChange={this.change}
                className="input-field my-3"
                placeholder="סדר בFEED"
              />
              <input
                type="submit"
                className="max-form-btn max-login-btn mt-5"
                value="בדקתי, כל הפרטים נכונים, צרף"
              />
            </form>
          </div>
        )}
        {this.state.sent && (
          <div className="rtl">
            <div>
              <img
                className="sent-image"
                src={require("../../../../images/envelope.png")}
                alt=""
              />
            </div>
            <div className="sent-text">
              איזה כיף שהצטרף לנו עובד חדש!
              <br />
              נשלח מייל לאישור לכתובת:
              <br />
              {this.state.sentTo}
            </div>
          </div>
        )}
      </AdminPages>
    );
  }
  componentWillMount() {
    this.getLovs();
  }
  handleSelectChange = (obj, { name }) => {
    if (obj === null) obj = { value: null };
    this.setState({
      newContent: {
        ...this.state.newContent,
        [name]: obj.value,
      },
    });
  };
  selectNotFound = ({ inputValue }) => {
    return `לא מצאנו את "${inputValue}"`;
  };
  toggleCalendar = (e) => {
    e && e.preventDefault();
    this.setState({ toggleDate: !this.state.toggleDate });
  };
  changeDate = (newdate) => {
    this.setState({
      newContent: {
        ...this.state.newContent,
        date: newdate,
      },
    });
  };
  change = (e) => {
    this.setState({
      newContent: {
        ...this.state.newContent,
        [e.target.name]: e.target.value,
      },
    });
  };
  submitLogin = (e) => {
    e.preventDefault();
    let send = {
      Name: this.state.newContent.Name,
      CategoryId: this.state.newContent.CategoryId,
      StartDays: this.state.newContent.StartDays,
      EndDays: this.state.newContent.EndDays,
      Description: this.state.newContent.Description,
      MinutesRequired: this.state.newContent.MinutesRequired,
      Refresh: this.state.newContent.Refresh,
      DisplayOrder: this.state.newContent.DisplayOrder,
      FeedDisplayOrder: this.state.newContent.FeedDisplayOrder,
    };

    let form = new FormData();
    form.append("data", JSON.stringify(send));
    form.append("ContentFile", this.ContentFile.files[0]);
    form.append("ImageFile", this.ImageFile.files[0]);
    Api.post("AdminPanel/CreateContent", form)
      .then((response) => {
        const {
          data: { Success /*, Payload*/, Message, ErrorCode },
        } = response;
        if (Success) {
          this.setState({
            sent: true,
            sentTo: this.state.newuser.email,
            newuser: {
              name: "",
              number: "",
              email: "",
              role: null,
              site: null,
              date: "",
            },
          });
        } else {
          this.setState({
            message: Errors["e" + ErrorCode]
              ? Errors["e" + ErrorCode]
              : Message,
          });
        }
      })
      .catch(() => {});
  };
  getLovs = () => {
    Api.post("/AdminPanel/GetLovs")
      .then((resp) => {
        const {
          data: { Payload /*  Success, Message, ErrorCode*/ },
        } = resp;
        const [Roles, Sites] = Payload.Lovs;
        this.setState({
          roles: Roles.Items,
          sites: Sites.Items,
        });
      })
      .catch((err) => {});
  };
}

export default CreateUser;
