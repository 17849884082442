import React, { Component } from "react";
import "./contents.css";
import Api from "utils/api";
import UserPageTemplate from "components/user-temp/user-pages-template";
import AdminContentCarousel from "./admin-contents-carousel";
import ContentModal from "./content-modal.jsx";
import nosleep from "../../../../utils/nosleep";

class Contents extends Component {
  state = {
    isModalOpen: false,
    username: "",
    contentOpen: false,
    contentUrl: "",
    contents: []
  };

  componentDidMount = () => {
    this.getContents();
  };

  getContents = () => {
    const url = this.props.admin
      ? "/AdminPanel/GetAdminContents"
      : `/AdminPanel/GetUserContents?UserId=${this.props.match.params.id}`;
    Api.post(url)
      .then(response => {
        const {
          data: { Payload /*Success, Message, ErrorCode*/ }
        } = response;
        // console.log(Payload);
        this.setState({
          contents: Payload.Username ? Payload.Contents : Payload,
          username: Payload.Username ? Payload.Username : ""
        });
      })
      .catch(() => {});
  };

  openContent = item => {
    nosleep.enable();
    console.log("no sleep enabled");

    this.setState({ contentUrl: item.Link, contentOpen: true });
    Api.post("/OnBoard/GetContent", { ContentId: item.ContentId })
      .then(response => {
        /*
        const {
          data: { 
            Payload ,
             Success,
              Message,
               ErrorCode }
        } = response;
        */
        // console.log(Payload);
      })
      .catch(() => {});
    // this.storyVideo.load();
    // console.log(this.storyVideo);
  };
  closeContent = () => {
    nosleep.disable();
    console.log("no sleep disabled");
    this.setState({ contentUrl: "", contentOpen: false });
    this.getContents();
  };

  toggleModal = () => {
    if (this.state.isModalOpen) {
      nosleep.enable();

      console.log("no sleep enabled");
    } else {
      nosleep.disable();
      console.log("no sleep disabled");
    }
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  render() {
    return (
      <React.Fragment>
        <div className="mobile-vh10" />

        <ContentModal
          isOpen={this.state.contentOpen}
          contentUrl={this.state.contentUrl}
          closeContent={this.closeContent}
        />

        <UserPageTemplate admin noMobile imgClass="admin-all-contents-img">
          {this.state.isModalOpen ? (
            <div className="screen-overlay" onClick={this.toggleModal} />
          ) : null}
          {this.props.admin ? (
            <div className="page-title mb-4 mt-2 font-weight-bold rtl">
              כל התכנים
            </div>
          ) : (
            <div className="page-title rtl font-weight-bold mb-4 mt-2">
              תמונת מצב על {this.state.username}
            </div>
          )}

          {this.state.contents.map((content, index) => {
            if (this.props.admin)
              return (
                <AdminContentCarousel
                  admin
                  getContents={this.getContents}
                  toggleModal={this.toggleModal}
                  content={content}
                  key={index}
                  openContent={this.openContent}
                />
              );
            return (
              <AdminContentCarousel
                userId={this.props.match.params.id}
                getContents={this.getContents}
                toggleModal={this.toggleModal}
                content={content}
                key={index}
                openContent={this.openContent}
              />
            );
          })}

          <div className={this.state.isModalOpen ? "shown" : "hidden"}>
            <div className="content-disabled-msg">
              <span
                onClick={this.toggleModal}
                className="close-menu-btn"
                style={{ fontSize: "40px" }}
              >
                &times;
              </span>
              <img
                src={require("../../../../images/clock.png")}
                alt=""
                width="150px"
                className="clock-img"
              />
              <div
                onClick={this.toggleModal}
                className="text-blue rtl text-center font-20px font-weight-bold mt-4"
              >
                אין מה למהר...
                <br />
                התוכן הזה ייפתח לך בקרוב
              </div>
            </div>
          </div>
        </UserPageTemplate>
      </React.Fragment>
    );
  }

  scrollRight = () => {
    let container = this.carouselContainer;
    let scrollAmount = 0;
    let slideTimer = setInterval(function() {
      container.scrollLeft += 5;
      scrollAmount += 5;
      if (scrollAmount >= 200) {
        window.clearInterval(slideTimer);
      }
    }, 10);
  };

  scrollLeft = () => {
    let container = this.carouselContainer;
    let scrollAmount = 0;
    let slideTimer = setInterval(function() {
      container.scrollLeft -= 5;
      scrollAmount += 5;
      if (scrollAmount >= 200) {
        window.clearInterval(slideTimer);
      }
    }, 10);
  };
}

export default Contents;
