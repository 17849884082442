import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Api from "utils/api.js";

import DatePicker from "components/datePicker/datePicker.jsx";
import "./edit-user.css";
import { EmailRegEx, EmailMax, DateFormat, Errors } from "utils/const.js";
import { format } from "date-fns";
class EditUser extends React.Component {
  state = {
    user: {
      name: "",
      number: "",
      email: "",
      roleName: "",
      siteName: "",
      date: "",
      sendmail: false
    },
    sentTo: "asdasd@asdasd.asd",
    sent: false,
    toggleDate: false,
    roles: [],
    sites: [],
    message: ""
  };
  onOpen = () => {
    this.setState({ message: "" });
  };
  toggle = () => {
    this.props.close();
  };
  render() {
    /* const roles = this.state.roles.map(item => ({
      label: item.Value,
      value: item.Key
    }));*/
    //const rolePick = roles.find(item => item.value === this.state.user.role);
    /*
    const sites = this.state.sites.map(item => ({
      label: item.Value,
      value: item.Key
    }));
    */
    //const sitePick = sites.find(item => item.value === this.state.user.site);
    return (
      <div>
        <Modal
          size="lg"
          onOpened={this.onOpen}
          isOpen={this.props.open}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle} style={{ border: "none" }} />
          <h3
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#231162"
            }}
          >
            עריכת משתמש
          </h3>
          <ModalBody>
            <div className="rtl text-right">
              <div className="rtl text-error text-center error-line">
                {this.state.message}
              </div>
              <form
                className="input-field-container-edit-user"
                onSubmit={this.submitLogin}
              >
                <input
                  type="text"
                  name="name"
                  value={this.state.user.name}
                  onChange={this.change}
                  className="input-field my-3"
                  placeholder="מה השם המלא?"
                />
                <input
                  type="text"
                  name="number"
                  value={this.state.user.number}
                  onChange={this.change}
                  className="input-field my-3"
                  placeholder="ומספר עובד?"
                />
                <input
                  type="email"
                  name="email"
                  value={this.state.user.email}
                  onChange={this.change}
                  className="input-field my-3"
                  placeholder="המייל בעבודה"
                />
                <input
                  type="email"
                  name="roleName"
                  value={this.state.user.roleName}
                  onChange={this.change}
                  className="input-field my-3"
                  placeholder="באיזה תפקיד?"
                />
                <input
                  type="email"
                  name="siteName"
                  value={this.state.user.siteName}
                  onChange={this.change}
                  className="input-field my-3"
                  placeholder="לאיזה אגף?"
                />
                {/*
                <Select
                  classNamePrefix="selectleumi"
                  isClearable
                  isRtl
                  isSearchable
                  value={rolePick ? rolePick : null}
                  name="role"
                  noOptionsMessage={this.selectNotFound}
                  onChange={this.handleSelectChange}
                  placeholder="לאיזה תפקיד הוא התקבל?"
                  options={roles}
                />
                <div className="sep" />
                <Select
                  classNamePrefix="selectleumi"
                  isClearable
                  isRtl
                  isSearchable
                  value={sitePick ? sitePick : null}
                  name="site"
                  onChange={this.handleSelectChange}
                  placeholder="לאיזה אגף?"
                  noOptionsMessage={this.selectNotFound}
                  options={sites}
                />
                */}
                <DatePicker
                  rtl
                  value={this.state.user.date}
                  change={this.changeDate}
                  placeholder="התאריך של יום העבודה הראשון אצלנו"
                  format={DateFormat}
                />
                <div className="text-right">
                  <label>
                    <input
                      type="checkbox"
                      name="sendmail"
                      value={this.state.user.sendmail}
                      onChange={this.change}
                    />
                    {"  "}
                    שלח אי-מייל ראשוני
                  </label>
                </div>
              </form>
            </div>
          </ModalBody>
          <ModalFooter style={{ margin: "0 auto" }}>
            <Button color="secondary" onClick={this.toggle}>
              חזרה
            </Button>
            <Button color="primary" onClick={this.submitLogin}>
              תעדכן אותי
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    ///set new edit user
    if (prevState.user.id !== nextProps.user.UserId) {
      const user = nextProps.user;
      return {
        user: {
          id: user.UserId,
          date: format(new Date(user.CreatedOn), DateFormat),
          name: user.Username,
          number: user.IdNum,
          email: user.Email,
          roleName: user.Role,
          siteName: user.Site,
          sendmail: false
        }
      };
    }
    return null;
  }
  componentDidMount() {
    this.getLovs();
  }
  getLovs = () => {
    Api.post("/AdminPanel/GetLovs")
      .then(resp => {
        const {
          data: { Payload /*,Success, Message, ErrorCode*/ }
        } = resp;
        const [Roles, Sites] = Payload.Lovs;
        this.setState({
          roles: Roles.Items,
          sites: Sites.Items
        });
      })
      .catch(err => {});
  };

  handleSelectChange = (obj, { name }) => {
    if (obj === null) obj = { value: null };
    this.setState({
      user: {
        ...this.state.user,
        [name]: obj.value
      }
    });
  };
  selectNotFound = ({ inputValue }) => {
    return `לא מצאנו את "${inputValue}"`;
  };
  toggleCalendar = e => {
    e && e.preventDefault();
    this.setState({ toggleDate: !this.state.toggleDate });
  };
  changeDate = newdate => {
    this.setState({
      user: {
        ...this.state.user,
        date: newdate
      }
    });
  };
  change = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      user: {
        ...this.state.user,
        [name]: value
      }
    });
  };
  submitLogin = e => {
    e.preventDefault();
    const name = this.state.user.name;
    let send = {
      Email: this.state.user.email,
      UserId: this.state.user.id,
      Firstname: name.substr(0, name.indexOf(" ")),
      Lastname: name.substr(name.indexOf(" ") + 1),
      Site: this.state.user.siteName,
      Role: this.state.user.roleName,
      IdNum: this.state.user.number,
      CreatedOn: this.state.user.date,
      SendMail: this.state.user.sendmail /////////shay
    };
    if (send.Firstname.trim() === "" || send.Lastname.trim() === "") {
      this.setState({
        message: Errors.e55
      });
      return;
    } else if (!/^[\d]+$/.test(send.IdNum)) {
      this.setState({
        message: Errors.e58
      });
      return;
    } else if (!EmailRegEx.test(send.Email)) {
      this.setState({
        message: Errors.e53
      });
      return;
    } else if (false && !EmailMax.test(send.Email)) {
      this.setState({
        message: Errors.e54
      });
      return;
    } else if (send.Role.trim() === "") {
      this.setState({
        message: Errors.e57
      });
      return;
    } else if (send.Site.trim() === "") {
      this.setState({
        message: Errors.e56
      });
      return;
    } else if (send.CreatedOn.trim() === "") {
      this.setState({
        message: Errors.e59
      });
      return;
    }

    Api.post("AdminPanel/UpdateUser", send)
      .then(response => {
        const {
          data: { Success, Message, ErrorCode /*Payload, */ }
        } = response;
        if (Success) {
          this.props.close(true);
        } else {
          this.setState({
            message: Errors["e" + ErrorCode] ? Errors["e" + ErrorCode] : Message
          });
        }
      })
      .catch(() => {});
  };
}

export default EditUser;
