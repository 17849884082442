import React, { Component } from "react";
import "./single-content.css";

class SingleContent extends Component {
  state = {
    content: {},
  };
  render() {
    const add = process.env.REACT_APP_CONTENT_BASE_URL;
    const type_help = this.props.link ? this.props.link.split(".") : [""];
    let type = "iframe";
    switch (type_help[type_help.length - 1].toLowerCase()) {
      case "mp4":
      case "mov":
        type = "video";
        break;
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
        type = "image";
        break;
      case "pdf":
        type = "PDF";
        break;
      default:
        type = "iframe";
    }
    if (this.props.link === undefined) {
      return <div />;
    }
    return (
      <React.Fragment>
        {/* <div className="overlay"></div> */}
        <div style={{ backgroundColor: "white" }}>
          {type === "video" && (
            <video className="in-content-video" controls autoPlay>
              <source src={add + this.props.link} />
            </video>
          )}
          {type === "image" && (
            <img
              src={add + this.props.link}
              className="in-content-image"
              alt=""
            />
          )}
          {type === "PDF" && (
            <iframe
              title="contentFrame"
              src={add + "/PDFJS/web/viewer.html?file=" + this.props.link}
              frameBorder="0"
              width="100%"
              className="in-content-iframe"
            />
          )}{" "}
          {type === "iframe" && (
            <iframe
              title="contentFrame"
              src={add + this.props.link}
              frameBorder="0"
              width="100%"
              className="in-content-iframe"
            />
          )}
          {/*/*
                    <iframe
            title="contentFrame"
            src={this.props.content.Link}
            frameBorder="0"
            width="100%"
            className="content-iframe"
          />

           */}
        </div>
      </React.Fragment>
    );
  }
}
export default SingleContent;
