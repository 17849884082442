import React, { Component } from "react";
import Api from "utils/api.js";

import DatePicker from "components/datePicker/datePicker.jsx";
import AdminPages from "components/admin-temp/admin-pages-template.jsx";
import "./create-user.css";
import { EmailRegEx, EmailMax, DateFormat, Errors } from "utils/const.js";

class CreateUser extends Component {
  state = {
    newuser: {
      name: "",
      number: "",
      email: "",
      roleName: "",
      siteName: "",
      date: ""
    },
    sentTo: "asdasd@asdasd.asd",
    sent: false,
    toggleDate: false,
    roles: [],
    sites: [],
    message: ""
  };
  render() {
    /*
    const roles = this.state.roles.map(item => ({
      label: item.Value,
      value: item.Key
    }));
    const rolePick = roles.find(item => item.value === this.state.newuser.role);
*/
    /*
    const sites = this.state.sites.map(item => ({
      label: item.Value,
      value: item.Key
    }));
    const sitePick = sites.find(item => item.value === this.state.newuser.site);
*/
    return (
      <AdminPages
        imgClass={!this.state.sent ? "admin-add-user-img" : "admin-user-added"}
      >
        {!this.state.sent && (
          <div>
            <div className="page-text rtl my-4 text-center text-blue">
              פרטים טכניים שחייבים <br /> למלא בשביל לצרף את העובד/ת
            </div>
            <div className="rtl text-error text-center error-line">
              {this.state.message}
            </div>
            <form
              className="input-field-container-create-user"
              onSubmit={this.submitLogin}
            >
              <input
                type="text"
                name="name"
                value={this.state.newuser.name}
                onChange={this.change}
                className="input-field my-3"
                placeholder="מה השם המלא?"
              />
              <input
                type="text"
                name="number"
                value={this.state.newuser.number}
                onChange={this.change}
                className="input-field my-3"
                placeholder="ומספר עובד?"
              />
              <input
                type="text"
                name="email"
                value={this.state.newuser.email}
                onChange={this.change}
                className="input-field my-3"
                placeholder="המייל בעבודה"
              />
              <input
                type="text"
                name="roleName"
                value={this.state.newuser.roleName}
                onChange={this.change}
                className="input-field my-3"
                placeholder="באיזה תפקיד?"
              />

              <input
                type="text"
                name="siteName"
                value={this.state.newuser.siteName}
                onChange={this.change}
                className="input-field my-3"
                placeholder="לאיזה אגף?"
              />
              {/* <Select
                classNamePrefix="selectleumi"
                isClearable
                isRtl
                isSearchable
                value={rolePick ? rolePick : null}
                name="role"
                noOptionsMessage={this.selectNotFound}
                onChange={this.handleSelectChange}
                placeholder="לאיזה תפקיד הוא התקבל?"
                options={roles}
              />
              <div className="sep" />
              <Select
                classNamePrefix="selectleumi"
                isClearable
                isRtl
                isSearchable
                value={sitePick ? sitePick : null}
                name="site"
                onChange={this.handleSelectChange}
                placeholder="לאיזה אגף?"
                noOptionsMessage={this.selectNotFound}
                options={sites}
              /> */}
              <DatePicker
                rtl
                value={this.state.newuser.date}
                change={this.changeDate}
                placeholder="התאריך של יום העבודה הראשון אצלנו"
                format={DateFormat}
              />
              <input
                type="submit"
                className="max-form-btn max-login-btn mt-5"
                value="בדקתי, כל הפרטים נכונים, צרף"
              />
            </form>
          </div>
        )}
        {this.state.sent && (
          <div className="rtl">
            <div>
              <img
                className="sent-image"
                src={require("../../../../images/envelope.png")}
                alt=""
              />
            </div>
            <div className="sent-text">
              איזה כיף שהצטרף לנו עובד חדש!
              <br />
              נשלח מייל לאישור לכתובת:
              <br />
              {this.state.sentTo}
            </div>
          </div>
        )}
      </AdminPages>
    );
  }
  componentWillMount() {
    this.getLovs();
  }
  handleSelectChange = (obj, { name }) => {
    if (obj === null) obj = { value: null };
    this.setState({
      newuser: {
        ...this.state.newuser,
        [name]: obj.value
      }
    });
  };
  selectNotFound = ({ inputValue }) => {
    return `לא מצאנו את "${inputValue}"`;
  };
  toggleCalendar = e => {
    e && e.preventDefault();
    this.setState({ toggleDate: !this.state.toggleDate });
  };
  changeDate = newdate => {
    this.setState({
      newuser: {
        ...this.state.newuser,
        date: newdate
      }
    });
  };
  change = e => {
    this.setState({
      newuser: {
        ...this.state.newuser,
        [e.target.name]: e.target.value
      }
    });
  };
  submitLogin = e => {
    e.preventDefault();
    const name = this.state.newuser.name;
    let send = {
      Email: this.state.newuser.email,
      //Password: this.state.newuser.,
      Firstname: name.substr(0, name.indexOf(" ")),
      Lastname: name.substr(name.indexOf(" ") + 1),
      Site: this.state.newuser.siteName,
      Role: this.state.newuser.roleName,
      IdNum: this.state.newuser.number,
      CreatedOn: this.state.newuser.date
    };

    if (send.Firstname.trim() === "" || send.Lastname.trim() === "") {
      this.setState({
        message: Errors.e55
      });
      return;
    } else if (!/^[\d]+$/.test(send.IdNum)) {
      this.setState({
        message: Errors.e58
      });
      return;
    } else if (!EmailRegEx.test(send.Email)) {
      this.setState({
        message: Errors.e53
      });
      return;
    } else if (false && !EmailMax.test(send.Email)) {
      this.setState({
        message: Errors.e54
      });
      return;
    } else if (send.Role.trim() === "") {
      this.setState({
        message: Errors.e57
      });
      return;
    } else if (send.Site.trim() === "") {
      this.setState({
        message: Errors.e56
      });
      return;
    } else if (send.CreatedOn.trim() === "") {
      this.setState({
        message: Errors.e59
      });
      return;
    }

    Api.post("AdminPanel/CreateUser", send)
      .then(response => {
        const {
          data: { Success /*, Payload*/, Message, ErrorCode }
        } = response;
        if (Success) {
          this.setState({
            sent: true,
            sentTo: this.state.newuser.email,
            newuser: {
              name: "",
              number: "",
              email: "",
              role: null,
              site: null,
              date: ""
            }
          });
        } else {
          this.setState({
            message: Errors["e" + ErrorCode] ? Errors["e" + ErrorCode] : Message
          });
        }
      })
      .catch(() => {});
  };
  getLovs = () => {
    Api.post("/AdminPanel/GetLovs")
      .then(resp => {
        const {
          data: { Payload /*  Success, Message, ErrorCode*/ }
        } = resp;
        const [Roles, Sites] = Payload.Lovs;
        this.setState({
          roles: Roles.Items,
          sites: Sites.Items
        });
      })
      .catch(err => {});
  };
}

export default CreateUser;
