import React, { Component } from "react";
import Navbar from "components/navbar/navbar";
import ImageGallery from "../../../components/image-gallery/image-gallery";

class Help extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div
          className="container-fluid"
          style={{ overflow: "hidden !important" }}
        >
          <div className="row my-vh100">
            <div className="col-md-3 bg-teal hidden-mobile-d-flex align-items-center justify-content-center">
              <div className="ie-fix" />
              <img
                className="temp1-max-logo hidden-mobile"
                src={require("../../../images/max_logo_white.svg")}
                alt=""
              />
            </div>
            <div className="col-md-9 px-0">
              <div className="hidden-mobile page-title mobile-mt2 mb-3 font-weight-bold rtl">
                עזרה
              </div>
              {/* Image Gallery */}
              <ImageGallery />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Help;
