import React, { Component } from "react";
import "./contents.css";
import Api from "utils/api";
import UserPageTemplate from "components/user-temp/user-pages-template";
import ContentCarousel from "./content-carousel";
import ContentModal from "./content-modal.jsx";

import nosleep from "../../../utils/nosleep";

class Contents extends Component {
  state = {
    isModalOpen: false,
    contentOpen: false,
    contentUrl: "",
    contents: []
  };

  componentDidMount = () => {
    this.getContents();
  };

  getContents = () => {
    Api.post("/OnBoard/GetMyContents")
      .then(response => {
        const {
          data: { Payload /*Success,Message, ErrorCode*/ }
        } = response;
        // console.log(Payload);
        this.setState({
          contents: Payload
        });
      })
      .catch(() => {});
  };

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };
  toggleLiked = id => {
    Api.post("/OnBoard/ToggleLikeContent", { ContentId: id })
      .then(response => {
        /*
        const {
          data: { Success, Payload, Message, ErrorCode }
        } = response;
        */
        this.getContents();
      })
      .catch(() => {});
  };

  openContent = item => {
    nosleep.enable();
    console.log("no sleep enabled");

    this.setState({ contentUrl: item.Link, contentOpen: true });
    Api.post("/OnBoard/GetContent", { ContentId: item.ContentId })
      .then(response => {
        /*const {
          data: {
            // Payload ,
            //  Success,
            // Message,
            //ErrorCode
          }
        } = response;
        */
        // console.log(Payload);
      })
      .catch(() => {});
    // this.storyVideo.load();
    // console.log(this.storyVideo);
  };
  closeContent = () => {
    nosleep.disable();
    console.log("no sleep disabled");
    this.setState({ contentUrl: "", contentOpen: false });
    this.getContents();
  };

  render() {
    return (
      <div style={{ overflow: "scroll !important" }}>
        <div className="mobile-vh10" />
        <ContentModal
          isOpen={this.state.contentOpen}
          contentUrl={this.state.contentUrl}
          closeContent={this.closeContent}
        />

        <UserPageTemplate noMobile imgClass="user-all-contents-img">
          {this.state.isModalOpen ? (
            <div className="screen-overlay" onClick={this.toggleModal} />
          ) : null}
          <div className="page-title mobile-mt2 mb-3 font-weight-bold rtl">
            התכנים שלי
          </div>

          {this.state.contents.map((content, index) => {
            return (
              <ContentCarousel
                content={content}
                getContents={this.getContents}
                toggleModal={this.toggleModal}
                openContent={this.openContent}
                key={index}
              />
            );
          })}

          {/* <div className="row mt-5 rtl">
              <div className="carousel-container">
                <h3 className="carousel-category text-right mb-4">קטגוריה</h3>
                <button
                  onClick={this.scrollRight}
                  className="scroll-left-button text-blue font-3em hidden-mobile"
                >
                  {"<"}
                </button>
                <button
                  onClick={this.scrollLeft}
                  className="scroll-right-button text-blue font-3em hidden-mobile"
                >
                  {">"}
                </button>
                <div
                  className="content-carousel-images"
                  ref={e => (this.carouselContainer = e)}
                >
                  <div className="content text-center bg-max-blue">
                    <img
                      className="content-image"
                      src={require("../../images/welcome.jpg")}
                    />
                    <h3 className="content-title text-white py-md-3 py-md-1">
                      שם פריט
                    </h3>
                    <div className="float-right text-white mb-3 mr-3">
                      <img src={require("../../images/eye.png")} width="20px" />
                    </div>
                    <div className="float-left text-white mb-3 ml-3">
                      <img
                        src={require("../../images/heart.png")}
                        width="15px"
                      />
                    </div>
                  </div>
                  <div className="content content-disabled text-center bg-max-blue">
                    <img
                      className="content-image"
                      src={require("../../images/welcome.jpg")}
                    />
                    <h3 className="content-title text-white py-md-3 py-md-1">
                      שם פריט
                    </h3>
                    <div className="float-right text-white mb-3 mr-3">
                      <img src={require("../../images/eye.png")} width="20px" />
                    </div>
                    <div className="float-left text-white mb-3 ml-3">
                      <img
                        src={require("../../images/heart.png")}
                        width="15px"
                      />
                    </div>
                  </div>
                  <div className="content text-center bg-max-blue">
                    <img
                      className="content-image"
                      src={require("../../images/welcome.jpg")}
                    />
                    <h3 className="content-title text-white py-md-3 py-md-1">
                      שם פריט
                    </h3>
                  </div>
                  <div className="content text-center bg-max-blue">
                    <img
                      className="content-image"
                      src={require("../../images/welcome.jpg")}
                    />
                    <h3 className="content-title text-white py-md-3 py-md-1">
                      שם פריט
                    </h3>
                  </div>
                  <div className="content text-center bg-max-blue">
                    <img
                      className="content-image"
                      src={require("../../images/welcome.jpg")}
                    />
                    <h3 className="content-title text-white py-md-3 py-md-1">
                      שם פריט
                    </h3>
                  </div>
                  <div className="content text-center bg-max-blue">
                    <img
                      className="content-image"
                      src={require("../../images/welcome.jpg")}
                    />
                    <h3 className="content-title text-white py-md-3 py-md-1">
                      שם פריט
                    </h3>
                  </div>
                  <div className="content text-center bg-max-blue">
                    <img
                      className="content-image"
                      src={require("../../images/welcome.jpg")}
                    />
                    <h3 className="content-title text-white py-md-3 py-md-1">
                      שם פריט
                    </h3>
                  </div>
                </div>
              </div>
            </div> */}
          <div className={this.state.isModalOpen ? "shown" : "hidden"}>
            <div className="content-disabled-msg">
              <span
                onClick={this.toggleModal}
                className="close-menu-btn"
                style={{ fontSize: "40px" }}
              >
                &times;
              </span>
              <img
                src={require("../../../images/clock.png")}
                alt=""
                width="150px"
                className="clock-img"
              />
              <div
                onClick={this.toggleModal}
                className="text-blue rtl text-center font-20px font-weight-bold mt-4"
              >
                אין מה למהר...
                <br />
                התוכן הזה ייפתח לך בקרוב
              </div>
            </div>
          </div>
        </UserPageTemplate>
      </div>
    );
  }

  scrollRight = () => {
    let container = this.carouselContainer;
    let scrollAmount = 0;
    let slideTimer = setInterval(function() {
      container.scrollLeft += 5;
      scrollAmount += 5;
      if (scrollAmount >= 200) {
        window.clearInterval(slideTimer);
      }
    }, 10);
  };

  scrollLeft = () => {
    let container = this.carouselContainer;
    let scrollAmount = 0;
    let slideTimer = setInterval(function() {
      container.scrollLeft -= 5;
      scrollAmount += 5;
      if (scrollAmount >= 200) {
        window.clearInterval(slideTimer);
      }
    }, 10);
  };
}

export default Contents;
